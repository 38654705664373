import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const AppRankingManagement = () => {
  const [apps, setApps] = useState([]);
  const [ranking, setRanking] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Fetch apps from Firestore
  useEffect(() => {
    const fetchApps = async () => {
      const appsCollection = collection(db, "apps");
      const appsSnapshot = await getDocs(appsCollection);
      const appList = appsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setApps(appList);
    };

    const fetchRanking = async () => {
      const rankingDoc = doc(db, "appRanking", "ranking");
      const rankingSnapshot = await getDocs(rankingDoc);
      if (rankingSnapshot.exists()) {
        setRanking(rankingSnapshot.data().apps || []);
      }
    };

    fetchApps();
    fetchRanking();
  }, []);

  // Handle Drag-and-Drop
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedRanking = Array.from(ranking);
    const [removed] = reorderedRanking.splice(result.source.index, 1);
    reorderedRanking.splice(result.destination.index, 0, removed);

    setRanking(reorderedRanking);
  };

  // Save ranking to Firestore
  const saveRanking = async () => {
    try {
      const rankingDocRef = doc(db, "appRanking", "ranking");
      await updateDoc(rankingDocRef, { apps: ranking });
      alert("Ranking saved successfully!");
    } catch (error) {
      console.error("Error saving ranking:", error);
      alert("Failed to save ranking. Please try again.");
    }
  };

  // Filter apps based on search query
  const filteredApps = apps.filter((app) =>
    app.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="flex h-screen">
      {/* Left Panel */}
      <div className="w-1/3 p-4 bg-gray-100 overflow-auto">
        <h2 className="text-lg font-bold mb-4">Available Apps</h2>
        <input
          type="text"
          placeholder="Search by name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
        />
        <ul>
          {filteredApps.map((app) => (
            <li
              key={app.id}
              className="p-2 mb-2 bg-white border rounded-lg cursor-pointer"
              onClick={() => {
                if (!ranking.find((item) => item.id === app.id)) {
                  setRanking([...ranking, app]);
                }
              }}
            >
              {app.title} - {app.developer}
            </li>
          ))}
        </ul>
      </div>

      {/* Right Panel */}
      <div className="w-2/3 p-4">
        <h2 className="text-lg font-bold mb-4">App Ranking</h2>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="rankingList">
            {(provided) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="space-y-2"
              >
                {ranking.map((app, index) => (
                  <Draggable key={app.id} draggableId={app.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="p-2 bg-white border rounded-lg flex justify-between items-center"
                      >
                        <span>{app.title}</span>
                        <button
                          onClick={() =>
                            setRanking(ranking.filter((item) => item.id !== app.id))
                          }
                          className="text-red-500"
                        >
                          Remove
                        </button>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>

        <button
          onClick={saveRanking}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          Save Ranking
        </button>
      </div>
    </div>
  );
};

export default AppRankingManagement;
