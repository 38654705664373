// src/pages/Index.js
import React, { useEffect, useState } from "react";
import user1 from "../images/user1.png";
import user2 from "../images/user2.png";
import user3 from "../images/user3.png";
import redeem from "../images/redeem.png";
import qwestexample from "../images/qwestexample.png";
import rewards from "../images/rewards.png";
import googlePlayBadge from "../images/google-play-badge.png";
import layer1 from "../images/layer1.png";
import layer2 from "../images/layer2.png";
import layer3 from "../images/layer3.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import "./customSwiper.css";

const Index = () => {
    const [scrollY, setScrollY] = useState(0);

    // Scroll event listener
    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="w-full text-white relative">
            {/* Hero Section */}
           <section className="relative min-h-screen bg-[#222831] flex flex-col items-center justify-center text-center px-6 z-0 overflow-hidden">
                {/* Background Layers with Parallax Effect */}
                <div className=" absolute bottom-0 left-1/2 transform -translate-x-1/2 h-1/3 w-full">
                    <div
                        className="absolute h-1/3 w-[120%] left-0 bg-cover bg-no-repeat bg-center blur-sm"
                        style={{
                            backgroundImage: `url(${layer1})`,
                            transform: `translateX(${scrollY * -0.1}px)`,
                        }}
                    ></div>
                    <div
                        className="absolute h-1/3 w-[120%] right-0 bg-cover bg-no-repeat bg-center blur-sm"
                        style={{
                            backgroundImage: `url(${layer2})`,
                            transform: `translateX(${scrollY * 0.1}px)`,
                            top: "40%",
                        }}
                    ></div>
                    <div
                        className="absolute h-1/3 w-[120%] left-0 bg-cover bg-no-repeat bg-center blur-sm"
                        style={{
                            backgroundImage: `url(${layer3})`,
                            transform: `translateX(${scrollY * -0.1}px)`,
                            top: "80%",
                        }}
                    ></div>
                </div>

                {/* Content */}
                <div className="relative z-10 p-4 sm:p-6 md:p-8 lg:p-10">
                    <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-8">
                        <span className="text-[#00ADB5] font-exo2">Play Games. </span>
                        <span className="text-[#EEEEEE] font-exo2">Earn Money.</span>
                    </h1>
                    <p className="font-exo2 text-lg sm:text-xl md:text-2xl lg:text-3xl max-w-4xl mx-auto leading-relaxed">
                        Play with your friends, dive into hundreds of thrilling games, and start earning rewards instantly.
                        Turn your achievements into real value with gift cards and cash rewards. With up to <span className="font-semibold">$250 per game</span>,
                        the fun never stops, and the rewards keep coming.
                    </p>
                    {/* Download Button */}
                    <div className="flex justify-center space-y-0">
                        <a href="https://play.google.com" target="_blank" rel="noopener noreferrer">
                            <img
                                src={googlePlayBadge}
                                alt="Download on Google Play"
                                className="h-30 p-6"
                            />
                        </a>
                    </div>
                </div>
            </section>

            {/* Sliding Panel Section */}
            <section className="bg-[#393E46] py-16 px-8 font-exo2 relative z-20">
                <div className="flex flex-col lg:flex-row gap-12">
                    {/* Left Side - Text Section */}
                    <div className="w-full lg:w-2/3 flex flex-col justify-top text-center p-6 lg:p-18">
                        <p className=" text-4xl md:text-6xl text-[#EEEEEE] mb-6 leading-relaxed">
                            <span className="text-[#00ADB5] font-bungee">earn money <br/></span>
                            <span className="text-[#EEEEEE] font-bungee">playing games? </span>
                        </p>
                        <p className="text-xl md:text-3xl text-[#EEEEEE] leading-relaxed text-center">
                            It's quick, simple, and fun.<br/>
                            Follow 3 easy steps and start earning rewards today!
                        </p>
                    <div className="flex justify-center">
                        <a href="https://play.google.com" target="_blank" rel="noopener noreferrer">
                            <img
                                src={googlePlayBadge}
                                alt="Download on Google Play"
                                className="h-30 p-6"
                            />
                        </a>
                    </div>
                    </div>

                    {/* Right Side - Slider Section */}
                    <div className="w-full lg:w-1/3 flex items-center justify-center">
                        <Swiper
                            spaceBetween={30}
                            slidesPerView={1}
                            pagination={{ clickable: true }}
                            autoplay={{ delay: 7000, disableOnInteraction: false }} 
                            modules={[Pagination, Autoplay]}
                            className="w-full max-w-lg pb-10" // Add padding bottom to push pagination below the block
                        >
                            {/* Step 1 */}
                            <SwiperSlide>
                                <div className="bg-[#222831] p-8 rounded-lg shadow-lg text-center">
                                    <img
                                        src={rewards}
                                        alt="Choose an Offer"
                                        className="w-full h-48 mb-6 object-contain bg-blend-darken"
                                    />
                                    <h3 className="text-2xl font-semibold mb-4 text-[#00ADB5]">Choose an Offer</h3>
                                    <p className="text-[#EEEEEE]">
                                        Browse high-paying offers. Play games. – the money is yours.
                                    </p>
                                </div>
                            </SwiperSlide>

                            {/* Step 2 */}
                            <SwiperSlide>
                                <div className="bg-[#222831] p-8 rounded-lg shadow-lg text-center">
                                    <img
                                        src={qwestexample}
                                        alt="Complete the Offer"
                                        className="w-full h-48 mb-6 object-contain bg-blend-darken"
                                    />
                                    <h3 className="text-2xl font-semibold mb-4 text-[#00ADB5]">Complete the Offer</h3>
                                    <p className="text-[#EEEEEE] mb-4">
                                        Most Qwests take 5-10 minutes. Earn money by reaching higher levels. Having fun as never been so profitable.
                                    </p>
                                </div>
                            </SwiperSlide>

                            {/* Step 3 */}
                            <SwiperSlide>
                                <div className="bg-[#222831] p-8 rounded-lg shadow-lg text-center">
                                    <img
                                        src={redeem}
                                        alt="Get Paid Instantly"
                                        className="w-full h-48 mb-6 object-contain bg-blend-darken"
                                    />
                                    <h3 className="text-2xl font-semibold mb-4 text-[#00ADB5]">Get Paid Instantly</h3>
                                    <p className="text-[#EEEEEE]">
                                        Withdraw your earnings starting at $5. Get paid with Visa, Google Play, Steam, or Amazon.
                                    </p>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>

            {/* Call to Action */}
            <section className="min-h-screen bg-[#00ADB5] flex flex-col items-center justify-center text-center px-6">
                <h2 className="text-4xl font-bold mb-6">Join the AppQwest Revolution</h2>
                <p className="text-lg max-w-3xl mb-8">
                    Turn gaming into rewards. Discover a platform that combines fun, engagement, and real-world incentives.
                </p>
                        <a href="https://play.google.com" target="_blank" rel="noopener noreferrer">
                            <img
                                src={googlePlayBadge}
                                alt="Download on Google Play"
                                className="h-30 p-6"
                            />
                        </a>
            </section>

            {/* Testimonials Section */}
            <section className="min-h-screen bg-[#222831] py-16 px-8">
                <h2 className="text-4xl font-bold text-center mb-12">What Our Users Say</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="bg-[#393E46] p-8 rounded-lg shadow-lg text-center">
                        <img src={user1} alt="User 1" className="w-24 h-24 rounded-full mx-auto mb-4" />
                        <p className="italic text-[#EEEEEE] mb-4">
                            "AppQwest completely changed how I discover games. I love earning rewards just for playing!"
                        </p>
                        <h4 className="font-semibold text-[#00ADB5]">- Jacob M.</h4>
                    </div>
                    <div className="bg-[#393E46] p-8 rounded-lg shadow-lg text-center">
                        <img src={user2} alt="User 2" className="w-24 h-24 rounded-full mx-auto mb-4" />
                        <p className="italic text-[#EEEEEE] mb-4">
                            "The Qwests and challenges keep me hooked every day. The rewards are just the cherry on top!"
                        </p>
                        <h4 className="font-semibold text-[#00ADB5]">- Emily R.</h4>
                    </div>
                    <div className="bg-[#393E46] p-8 rounded-lg shadow-lg text-center">
                        <img src={user3} alt="User 3" className="w-24 h-24 rounded-full mx-auto mb-4" />
                        <p className="italic text-[#EEEEEE] mb-4">
                            "A must-have app for any casual gamer. Engaging and rewarding experience like no other."
                        </p>
                        <h4 className="font-semibold text-[#00ADB5]">- Lily L.</h4>
                    </div>
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-[#222831] py-8 text-center">
                <p className="text-[#EEEEEE] text-sm">
                    &copy; {new Date().getFullYear()} AppQwest. All rights reserved. 
                    <a href="/terms" className="text-[#00ADB5] hover:underline ml-2">Terms of Use</a>
                </p>
            </footer>
        </div>
    );
};

export default Index;
