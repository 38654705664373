import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import CountUp from "react-countup";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import googlePlayBadge from "../images/google-play-badge.png";
import user1 from "../images/user1.png";
import user2 from "../images/user2.png";
import user3 from "../images/user3.png";
import game1 from "../images/user3.png"; // Replace with your actual images
import game2 from "../images/user3.png";
import game3 from "../images/user3.png";

const GamersLanding = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="w-full text-charcoal relative">
      {/* Enhanced Hero Section */}
      <section className="relative min-h-screen bg-verylightgray flex flex-col items-center justify-center text-center px-6 z-0 overflow-hidden">
        {/* Background image for ambiance */}
        <div
          className="absolute inset-0 bg-cover bg-center opacity-20"
          style={{ backgroundImage: `url('/path/to/hero-background.jpg')` }}
        ></div>
        <div className="relative z-10 p-4">
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-8 font-bungee">
            <span className="text-flashygreen">Play Games. </span>
            <span className="text-charcoal">Earn Rewards.</span>
          </h1>
          <p className="font-outfit text-lg sm:text-xl md:text-2xl lg:text-3xl max-w-4xl mx-auto leading-relaxed">
            Join a vibrant community where your gaming skills turn into real rewards.
            Compete in daily challenges, conquer leaderboards, and celebrate your achievements.
          </p>
          <div className="mt-8">
            <a href="https://play.google.com" target="_blank" rel="noopener noreferrer">
              <img src={googlePlayBadge} alt="Download on Google Play" className="h-12" />
            </a>
          </div>
        </div>
      </section>

      {/* Animated Counters Section */}
      <section className="bg-white py-12 px-6">
        <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-3 gap-8 text-center">
          <div>
            <h2 className="text-4xl font-bungee text-flashygreen">
              <CountUp start={0} end={50000} duration={3} />+
            </h2>
            <p className="font-outfit text-lg text-charcoal mt-2">Rewards Issued</p>
          </div>
          <div>
            <h2 className="text-4xl font-bungee text-flashygreen">
              <CountUp start={0} end={20000} duration={3} />+
            </h2>
            <p className="font-outfit text-lg text-charcoal mt-2">Active Gamers</p>
          </div>
          <div>
            <h2 className="text-4xl font-bungee text-flashygreen">
              <CountUp start={0} end={1000} duration={3} />+
            </h2>
            <p className="font-outfit text-lg text-charcoal mt-2">Daily Challenges</p>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="bg-white py-16 px-8 font-outfit relative z-20">
        <div className="flex flex-col lg:flex-row gap-12 items-center">
          <div className="w-full lg:w-2/3 text-center lg:text-left">
            <h2
              className="text-3xl md:text-4xl font-bold mb-6 font-bungee"
              style={{ color: "#00C876" }}
            >
              More Than Just Games
            </h2>
            <p className="text-lg md:text-xl text-charcoal">
              Experience the thrill of daily challenges, epic leaderboards, and social play. Our platform
              is designed to reward your passion for gaming, every single day.
            </p>
            <div className="mt-8">
              <a
                href="https://play.google.com"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-flashygreen text-white py-3 px-8 rounded-full font-bold hover:bg-green-600 transition"
              >
                Download the App
              </a>
            </div>
          </div>
          <div className="w-full lg:w-1/3">
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              pagination={{ clickable: true }}
              autoplay={{ delay: 7000, disableOnInteraction: false }}
              modules={[Pagination, Autoplay]}
              className="w-full"
            >
              <SwiperSlide>
                <div className="bg-verylightgray p-8 rounded-lg shadow-lg text-center">
                  <img src={user1} alt="Leaderboards" className="w-full h-48 mb-6 object-contain" />
                  <h3 className="text-2xl font-semibold mb-4 text-flashygreen">Leaderboards</h3>
                  <p className="text-charcoal">Compete with top gamers and see your name in lights.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-verylightgray p-8 rounded-lg shadow-lg text-center">
                  <img src={user2} alt="Daily Challenges" className="w-full h-48 mb-6 object-contain" />
                  <h3 className="text-2xl font-semibold mb-4 text-flashygreen">Daily Challenges</h3>
                  <p className="text-charcoal">New challenges every day to keep you on your toes.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-verylightgray p-8 rounded-lg shadow-lg text-center">
                  <img src={user3} alt="Social Play" className="w-full h-48 mb-6 object-contain" />
                  <h3 className="text-2xl font-semibold mb-4 text-flashygreen">Social Play</h3>
                  <p className="text-charcoal">Connect with friends and share your achievements.</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="bg-verylightgray py-16 px-8">
        <h2
          className="text-4xl font-bold text-center mb-12 font-bungee"
          style={{ color: "#00C876" }}
        >
          How It Works
        </h2>
        <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
          <div className="p-6 bg-white rounded-lg shadow-lg hover:shadow-2xl transition">
            <h3 className="text-2xl font-bold font-bungee mb-4" style={{ color: "#00C876" }}>
              Step 1
            </h3>
            <p className="font-outfit text-lg text-charcoal">
              Sign up and download the AppQwest app from Google Play.
            </p>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-lg hover:shadow-2xl transition">
            <h3 className="text-2xl font-bold font-bungee mb-4" style={{ color: "#00C876" }}>
              Step 2
            </h3>
            <p className="font-outfit text-lg text-charcoal">
              Engage in daily challenges, earn points, and climb the leaderboards.
            </p>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-lg hover:shadow-2xl transition">
            <h3 className="text-2xl font-bold font-bungee mb-4" style={{ color: "#00C876" }}>
              Step 3
            </h3>
            <p className="font-outfit text-lg text-charcoal">
              Redeem your points for exciting rewards—from gift cards to cash prizes.
            </p>
          </div>
        </div>
      </section>

      {/* Featured Games Section */}
      <section className="bg-white py-16 px-8">
        <h2
          className="text-4xl font-bold text-center mb-12 font-bungee"
          style={{ color: "#00C876" }}
        >
          Featured Games
        </h2>
        <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-3 gap-8">
          <div className="bg-verylightgray p-4 rounded-lg shadow-lg hover:shadow-2xl transition">
            <img src={game1} alt="Game 1" className="w-full h-48 object-cover rounded-t-lg" />
            <div className="p-4">
              <h3 className="text-2xl font-bold font-bungee" style={{ color: "#00C876" }}>
                Game Title 1
              </h3>
              <p className="font-outfit text-charcoal mt-2">
                Experience thrilling gameplay and earn rewards as you progress.
              </p>
            </div>
          </div>
          <div className="bg-verylightgray p-4 rounded-lg shadow-lg hover:shadow-2xl transition">
            <img src={game2} alt="Game 2" className="w-full h-48 object-cover rounded-t-lg" />
            <div className="p-4">
              <h3 className="text-2xl font-bold font-bungee" style={{ color: "#00C876" }}>
                Game Title 2
              </h3>
              <p className="font-outfit text-charcoal mt-2">
                Join the battle and prove your skills to win big rewards.
              </p>
            </div>
          </div>
          <div className="bg-verylightgray p-4 rounded-lg shadow-lg hover:shadow-2xl transition">
            <img src={game3} alt="Game 3" className="w-full h-48 object-cover rounded-t-lg" />
            <div className="p-4">
              <h3 className="text-2xl font-bold font-bungee" style={{ color: "#00C876" }}>
                Game Title 3
              </h3>
              <p className="font-outfit text-charcoal mt-2">
                Discover new worlds and challenges that push your limits.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="bg-verylightgray py-16 px-8">
        <h2
          className="text-4xl font-bold text-center mb-12 font-bungee"
          style={{ color: "#00C876" }}
        >
          What Our Gamers Say
        </h2>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <img src={user1} alt="User 1" className="w-24 h-24 rounded-full mx-auto mb-4" />
            <p className="italic text-charcoal mb-4">
              "AppQwest transformed my gaming experience – rewards and fun all in one!"
            </p>
            <h4 className="font-semibold" style={{ color: "#00C876" }}>
              - Alex
            </h4>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <img src={user2} alt="User 2" className="w-24 h-24 rounded-full mx-auto mb-4" />
            <p className="italic text-charcoal mb-4">
              "Daily challenges and leaderboards keep me coming back for more."
            </p>
            <h4 className="font-semibold" style={{ color: "#00C876" }}>
              - Jamie
            </h4>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <img src={user3} alt="User 3" className="w-24 h-24 rounded-full mx-auto mb-4" />
            <p className="italic text-charcoal mb-4">
              "The social aspect of AppQwest makes gaming a community event!"
            </p>
            <h4 className="font-semibold" style={{ color: "#00C876" }}>
              - Taylor
            </h4>
          </div>
        </div>
      </section>

      {/* Final Call to Action Section */}
      <section className="bg-flashygreen py-16 px-8 text-center">
        <h2 className="text-4xl font-bold mb-6 font-bungee text-white">
          Ready to Level Up Your Gaming?
        </h2>
        <p className="font-outfit text-lg mb-8 text-white max-w-2xl mx-auto">
          Join thousands of gamers who are already earning rewards and dominating the leaderboards.
          Download the app and start your journey today!
        </p>
        <a
          href="https://play.google.com"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-white text-flashygreen py-3 px-8 rounded-full font-bold hover:bg-gray-200 transition"
        >
          Download Now
        </a>
      </section>

      {/* Footer */}
      <footer className="bg-white py-8 text-center border-t border-gray-200">
        <p className="text-graygoogle text-sm">
          &copy; {new Date().getFullYear()} AppQwest. All rights reserved.
          <a href="/terms" className="text-flashygreen hover:underline ml-2">
            Terms of Use
          </a>
        </p>
      </footer>
    </div>
  );
};

export default GamersLanding;
