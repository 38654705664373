import React, { useState, useEffect } from "react";
import { db, rtdb } from "../firebaseConfig";
import { collection, getDocs, deleteDoc, doc, setDoc } from "firebase/firestore";
import { ref, remove } from "firebase/database";
import { v4 as uuidv4 } from "uuid";

const TestUsers = () => {
    const [testUsers, setTestUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchTestUsers();
    }, []);

    // **Fetch Test Users**
    const fetchTestUsers = async () => {
        setLoading(true);
        try {
            const querySnapshot = await getDocs(collection(db, "users"));
            const fetchedUsers = querySnapshot.docs
                .map((doc) => ({ id: doc.id, ...doc.data() }))
                .filter((user) => user.isTestData === true); // Only test users

            setTestUsers(fetchedUsers);
        } catch (error) {
            console.error("Error fetching test users:", error);
        }
        setLoading(false);
    };

    // **Delete Test User**
    const deleteTestUser = async (userId) => {
        if (!window.confirm("Are you sure you want to delete this test user?")) return;

        try {
            // 1️⃣ Remove from Firestore `users`
            await deleteDoc(doc(db, "users", userId));

            // 2️⃣ Remove from Firestore `leaderboard`
            const leaderboardRef = doc(db, "leaderboard", "ranking");
            const leaderboardSnapshot = await getDocs(collection(db, "leaderboard"));
            if (leaderboardSnapshot.exists()) {
                await setDoc(leaderboardRef, { [userId]: null }, { merge: true });
            }

            // 3️⃣ Remove from Firestore `challenges`
            await deleteDoc(doc(db, "challenges", userId));

            // 4️⃣ Remove from Realtime Database `users_qwest`
            await remove(ref(rtdb, `users_qwest/${userId}`));

            // Update UI after deletion
            setTestUsers(testUsers.filter((user) => user.id !== userId));

            alert("Test user deleted successfully!");
        } catch (error) {
            console.error("Error deleting test user:", error);
            alert("Failed to delete test user.");
        }
    };

    // **Generate a Test User**
    const generateTestUser = async () => {
        setLoading(true);
        try {
            const userId = uuidv4(); // Unique test user ID

            // 1️⃣ Create Firestore `users` entry
            const newUser = {
                uid: userId,
                email: `testuser${Math.floor(Math.random() * 1000)}@test.com`,
                nickname: `TestUser${Math.floor(Math.random() * 1000)}`,
                initials: "TU",
                installedApps: ["com.aqupepgames.projectpepe", "com_scopely_monopolygo"],
                mostvaluedgame: "Unknown Game",
                totalValue: Math.floor(Math.random() * (2000 - 1000 + 1)) + 1000,
                xp: 0,
                role: "user",
                isTestData: true, // TAGGED AS TEST DATA
            };
            await setDoc(doc(db, "users", userId), newUser);

            // 2️⃣ Create Firestore `leaderboard` entry
            const leaderboardEntry = {
                initials: "TU",
                nickname: newUser.nickname,
                mostvaluedgame: "Unknown Game",
                totalValue: Math.floor(Math.random() * (2000 - 1000 + 1)) + 1000,
            };
            await setDoc(doc(db, "leaderboard", "ranking"), { [userId]: leaderboardEntry }, { merge: true });

            // 3️⃣ Create Firestore `challenges`
            const challenges = {
                appId: "com.aqupepgames.projectpepe",
                qwests: [
                    { qwestId: "5trdki2eo", title: "Reach lvl 1", value: 100 },
                    { qwestId: "zaavbekhm", title: "Reach lvl 3", value: 300 },
                ],
                totalValue: 6100,
            };
            await setDoc(doc(db, "challenges", userId), challenges);

            // 4️⃣ Create Realtime DB `users_qwest`
            await setDoc(doc(db, "users_qwest", userId), {
                apps: {
                    com_aqupepgames_projectpepe: {
                        qwests: { "5trdki2eo": 1, "zaavbekhm": 1 },
                    },
                },
            });

            alert("Test user generated successfully!");
            fetchTestUsers(); // Refresh UI
        } catch (error) {
            console.error("Error generating test user:", error);
            alert("Failed to generate test user.");
        }
        setLoading(false);
    };

    return (
    <div className="p-6">
        <h1 className="text-2xl font-bold mb-6">Test Users Management</h1>

        <button
            onClick={generateTestUser}
            className="bg-green-500 text-white px-4 py-2 rounded-md mb-4"
            disabled={loading}
        >
            {loading ? "Generating..." : "Generate Test User"}
        </button>

        {loading && <p className="text-gray-500">Loading test users...</p>} {/* ✅ ADD LOADING MESSAGE */}

        {testUsers.length === 0 && !loading && (
            <p className="text-gray-500">No test users found.</p>
        )} {/* ✅ Show Message if Empty */}

        <div className="border rounded-md">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Email
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Nickname
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {testUsers.map((user) => (
                        <tr key={user.id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                {user.email}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                {user.nickname}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                <button
                                    className="text-red-500 hover:underline"
                                    onClick={() => deleteTestUser(user.id)}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
)};


export default TestUsers;
