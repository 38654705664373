import React, { useState } from "react";
import { db } from "../firebaseConfig";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

const DeleteAccountRequest = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (!email) {
      setError("Please enter your email address.");
      return;
    }

    setLoading(true);
    try {
      // Add the email and current timestamp to the "accountDeletionRequests" collection.
      await addDoc(collection(db, "accountDeletionRequests"), {
        email,
        createdAt: serverTimestamp(),
      });
      setSuccess("Your account deletion request has been submitted.");
      setEmail("");
    } catch (err) {
      console.error("Error submitting deletion request: ", err);
      setError("There was an error submitting your request. Please try again later.");
    }
    setLoading(false);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-6">
      <div className="max-w-md w-full p-6 bg-white shadow rounded">
        <h2 className="text-xl font-bold mb-4">Delete Account</h2>
        <p className="mb-4">Enter your email below to request account deletion.</p>
        <form onSubmit={handleSubmit}>
          <label className="block mb-2">
            <span className="text-gray-700">Email</span>
            <input
              type="email"
              className="mt-1 block w-full border border-gray-300 rounded p-2"
              placeholder="your-email@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
          <button
            type="submit"
            className="w-full bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit Request"}
          </button>
        </form>
        {success && <p className="text-green-500 mt-4">{success}</p>}
        {error && <p className="text-red-500 mt-4">{error}</p>}
      </div>
    </div>
  );
};

export default DeleteAccountRequest;
