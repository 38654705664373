import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="pt-24 w-full bg-[#222831] text-white py-6 px-6 font-exo2">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-8">
          AppQwest User Privacy Notice
        </h1>
        <p className="text-sm text-gray-400 text-center mb-12">
          LAST UPDATED: March 12, 2025
        </p>

        <p className="mb-4 text-gray-300">
          Please read the terms of this AppQwest User Privacy Notice (this “
          <strong>Notice</strong>”) carefully. The Notice describes how AppQwest
          Inc. (“<strong>AppQwest</strong>,” “<strong>we</strong>,” “<strong>us</strong>,” or “
          <strong>our</strong>”) collects, uses, discloses, or otherwise processes your
          personal data in connection with websites, mobile applications, progressive web
          applications, content, features, digital events, referral programs, promotions, or
          products we create or operate (the “<strong>Services</strong>”).
        </p>

        <p className="mb-4 text-gray-300">
          This Notice is incorporated into and forms a part of AppQwest’s Global Terms of
          Use (the “<strong>Terms</strong>”). Capitalized terms used below but not defined here
          shall have the meaning given to them in the Terms. In this Notice, “personal data” and
          “personal information” generally mean any information that directly or indirectly
          identifies you, which might vary depending on applicable laws.
        </p>

        <p className="mb-4 text-gray-300">
          <strong>Important:</strong> If you are younger than 18, you are prohibited from registering
          for an AppQwest user account.
        </p>

        <p className="mb-4 text-gray-300">
          We may revise or otherwise modify this Notice (for example, to reflect changes in the
          Services). If we modify it, we will update the “Last Updated” date at the top of this
          Notice and provide an alert or notification within the Services. If you do not accept the
          current Notice, including any relevant addendums in{" "}
          <a href="#annex-a" className="text-blue-400 hover:underline">
            Annex A: Region-Specific Notices
          </a>
          , please do not use the Services.
        </p>

        <hr className="my-6 border-gray-700" />

        <h2 className="text-2xl font-semibold mt-6 mb-4">1. INFORMATION WE COLLECT ABOUT YOU</h2>
        <p className="mb-4 text-gray-300">
          AppQwest is the “controller” of personal data within the meaning of applicable laws,
          which means we decide how and why your personal information is used and processed. We
          collect the following categories of information:
        </p>
        <ul className="list-disc ml-6 mb-4 text-gray-300">
          <li className="mb-2">
            <strong>Account Information.</strong> Your name, age, gender, phone number, passwords,
            and email when you set up an AppQwest account, including information provided by third
            parties you select during sign up (e.g., Facebook, Google).
          </li>
          <li className="mb-2">
            <strong>Activity and Engagement.</strong> Information about your activity within the
            AppQwest platform, including game installations, play time, participation in surveys,
            sweepstakes, tasks, and promotional offers.
          </li>
          <li className="mb-2">
            <strong>Device Information.</strong> Data such as your device model, operating system,
            device ID, advertising identifiers, IP address, and device location.
          </li>
          <li className="mb-2">
            <strong>Installed Apps.</strong> Information related to apps installed on your device,
            which helps us offer improved recommendations and protect against fraudulent activity.
          </li>
          <li className="mb-2">
            <strong>Usage Data.</strong> Session ID, how frequently you access the Services, the
            time, duration, and device(s) you use, details on active usage intervals, in-app
            events, tasks, sweepstakes, leaderboards, surveys, ratings, approximate and precise
            location, or the country from which you access the Services.
          </li>
          <li className="mb-2">
            <strong>Gameplay Data.</strong> Data about the software or applications when you play
            and engage with games discovered in our Service. This may include installation details,
            in-game purchase or advertising information, transaction types, events, clicks,
            impressions, use of virtual items, game achievements, and error logs.
          </li>
        </ul>
        <p className="mb-4 text-gray-300">
          For details on the precise categories of personal data we collect and the legal basis for doing
          so, see{" "}
          <a href="#annex-b" className="text-blue-400 hover:underline">
            Annex B: Table for Legal Processing Activities
          </a>
          .
        </p>

        <hr className="my-6 border-gray-700" />

        <h2 className="text-2xl font-semibold mt-6 mb-4">2. USE OF PERSONAL DATA</h2>
        <p className="mb-4 text-gray-300">
          We may link or combine personal data we collect through a range of techniques and
          technologies, including artificial intelligence or machine learning, to provide you with a
          personalized experience. We generally use personal data to:
        </p>
        <ul className="list-disc ml-6 mb-4 text-gray-300">
          <li className="mb-2">
            <strong>Provide and improve our Services.</strong> This includes handling customer support,
            responding to requests, trouble-shooting software bugs, and delivering, monitoring, and
            personalizing our Services.
          </li>
          <li className="mb-2">
            <strong>Personalize advertisements and promotions of games.</strong> We tailor suggestions of
            games, features, and events based on your usage history, preferences, location, and loyalty
            status.
          </li>
          <li className="mb-2">
            <strong>Display public information (leaderboards, community features).</strong> We may
            publicly display your username, rewards progress, leaderboard rank, and more to you or other
            users.
          </li>
          <li className="mb-2">
            <strong>Operate promotions and events.</strong> We run leaderboards, sweepstakes, promotions,
            and other digital events, and determine winners and distribute rewards.
          </li>
          <li className="mb-2">
            <strong>Grow the AppQwest community.</strong> By marketing our Services and measuring the
            performance of campaigns on third-party advertising platforms, we aim to reach potential new
            users.
          </li>
          <li className="mb-2">
            <strong>Fraud prevention.</strong> We verify digital identity, detect fraudulent activities
            using automated and manual procedures, and protect the integrity of our Services.
          </li>
          <li className="mb-2">
            <strong>Create insights and reports.</strong> We produce aggregate data and anonymized insights
            to develop new features, plan operational improvements, and test IT systems.
          </li>
          <li className="mb-2">
            <strong>Train machine learning &amp; AI models.</strong> We use data to improve
            personalization, user recommendations, and other platform services.
          </li>
          <li className="mb-2">
            <strong>Legal compliance.</strong> We abide by local laws, fulfill our legal obligations,
            and cooperate with authorities or regulatory bodies when required.
          </li>
        </ul>
        <p className="mb-4 text-gray-300">
          The legal bases for these processing activities may include (i) performance of a contract (i.e.,
          our agreement with you), (ii) compliance with legal obligations, (iii) legitimate interests
          (such as security, growth, product development, and fraud prevention), or (iv) consent as
          required by law. Additional details on legal bases can be found in{" "}
          <a href="#annex-b" className="text-blue-400 hover:underline">
            Annex B
          </a>
          .
        </p>

        <hr className="my-6 border-gray-700" />

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          3. HOW WE SHARE PERSONAL INFORMATION ABOUT YOU
        </h2>
        <p className="mb-4 text-gray-300">
          We may share some of your personal data with others under the following circumstances:
        </p>
        <ol className="list-decimal ml-6 mb-4 text-gray-300">
          <li className="mb-2">
            <strong>Public Profile and Leaderboards:</strong> Your username, gaming activity, and rewards
            progress may be visible to others on leaderboards or other community features. You can
            contact support to opt out of these features.
          </li>
          <li className="mb-2">
            <strong>Corporate Affiliates and Transaction Parties:</strong> We share data with subsidiaries
            or affiliates for the purposes described in this Notice. We may also share information in
            connection with significant corporate transactions, such as a merger or sale of assets,
            subject to applicable laws.
          </li>
          <li className="mb-2">
            <strong>Law Enforcement, Regulators, and Other Legal Requirements:</strong> We disclose
            personal data to comply with applicable laws or protect the rights and safety of AppQwest,
            users, or others. We may provide relevant information to government entities and third-party
            security firms under lawful conditions.
          </li>
          <li className="mb-2">
            <strong>Service Providers:</strong> We work with vendors who handle various functions like
            customer support, infrastructure, payment, analytics, or event and rewards facilitation.
            These providers process data on our behalf under contractual obligations consistent with
            this Notice.
          </li>
          <li className="mb-2">
            <strong>Advertising Platforms and Networks:</strong> We partner with advertising providers to
            market AppQwest across multiple channels. We share device IDs, IP addresses, event
            timestamps, and limited user data to track, measure, and optimize campaign performance.
          </li>
          <li className="mb-2">
            <strong>Game Partners:</strong> To track and reward your engagement with games promoted by
            AppQwest, you instruct us to collect Gameplay Data from these Game Partners. They may also
            collect certain data (e.g., installs, in-game purchases) for billing and attribution.
          </li>
          <li className="mb-2">
            <strong>Aggregated or De-identified Data:</strong> We may share anonymized information, which
            cannot be used to identify you, for business insights, analytics, or other lawful purposes.
          </li>
        </ol>

        <hr className="my-6 border-gray-700" />

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          4. HOW WE STORE, TRANSFER, AND SECURE PERSONAL INFORMATION ABOUT YOU
        </h2>
        <p className="mb-4 text-gray-300">
          AppQwest is based in France, and we primarily store collected information in the United States
          using third-party hosting services. Information we collect may be transferred, stored, or used
          globally, subject to contractual safeguards where required. We maintain administrative,
          technical, and organizational security measures designed to protect your personal data from
          unauthorized access, loss, misuse, or alteration.
        </p>
        <p className="mb-4 text-gray-300">
          If you reside in a jurisdiction imposing data localization or transfer requirements (e.g.,
          the EU, UK, or certain states), see the{" "}
          <a href="#annex-a" className="text-blue-400 hover:underline">
            Region-Specific Notices
          </a>{" "}
          for more information.
        </p>

        <hr className="my-6 border-gray-700" />

        <h2 className="text-2xl font-semibold mt-6 mb-4">5. EXERCISING YOUR RIGHTS</h2>
        <p className="mb-4 text-gray-300">
          Depending on your region, you may have certain rights over your personal data, such as
          access, correction, deletion, objection, or withdrawal of consent. If you choose to modify or
          withdraw certain permissions, some features of our Services (like reward eligibility) may no
          longer function properly.
        </p>
        <ul className="list-disc ml-6 mb-4 text-gray-300">
          <li className="mb-2">
            <strong>Opt out of marketing communications.</strong> You can unsubscribe by clicking the
            link in our emails or adjusting your preferences in the AppQwest platform.
          </li>
          <li className="mb-2">
            <strong>Withdraw consent.</strong> Where we rely on consent to process your data, you may
            withdraw it. Note that this can impact your ability to use certain features or Services.
          </li>
          <li className="mb-2">
            <strong>Erase your personal data.</strong> You may <a href="/deleteaccount">delete your account</a> at any time. Any
            unredeemed rewards (if applicable) will be forfeited.
          </li>
          <li className="mb-2">
            <strong>Object to certain processing or tracking technologies.</strong> You can contact us
            about objecting to specific use cases, though some functionalities may be disabled if you do
            so.
          </li>
          <li className="mb-2">
            <strong>Opt out of sharing or selling data to advertisers.</strong> If applicable in your
            jurisdiction, you can request that AppQwest not share your data with third parties for
            cross-context behavioral advertising.
          </li>
          <li className="mb-2">
            <strong>Opt out of targeted ads and content personalization.</strong> This is central to our
            rewards program; turning it off means you may lose reward-earning capabilities. You can
            request a more generic version of our service if you wish.
          </li>
        </ul>
        <p className="mb-4 text-gray-300">
          For more information on your privacy rights in specific regions, see{" "}
          <a href="#annex-a" className="text-blue-400 hover:underline">
            Annex A: Region-Specific Notices
          </a>
          . We will handle your request as required by law, including identity verification and response
          time requirements.
        </p>

        <hr className="my-6 border-gray-700" />

        <h2 className="text-2xl font-semibold mt-6 mb-4">6. OTHER IMPORTANT INFORMATION</h2>
        <h3 className="text-xl font-semibold mt-4 mb-2">6.1 CHILDREN’S PRIVACY</h3>
        <p className="mb-4 text-gray-300">
          AppQwest is not directed to users under the age of 18, and such individuals are prohibited from
          using our Services or registering for an account. If you believe we have inadvertently collected
          information from a minor, please contact us.
        </p>
        <h3 className="text-xl font-semibold mt-4 mb-2">6.2 DATA RETENTION</h3>
        <p className="mb-4 text-gray-300">
          We retain personal data only as long as needed for the purposes set out in this Notice, or as
          otherwise required by law (e.g., tax or billing obligations). For instance, if a user is inactive
          for a prolonged period, the account may be deactivated, rewards may expire, and relevant personal
          data deleted as permitted by law.
        </p>
        <h3 className="text-xl font-semibold mt-4 mb-2">6.3 COOKIES AND OTHER TRACKING TECHNOLOGIES</h3>
        <p className="mb-4 text-gray-300">
          AppQwest and our Game Partners use technologies such as cookies, web beacons, mobile measurement
          providers, or embedded software development kits (SDKs) to help us deliver and optimize the
          Services, personalize content, measure performance, and track engagement. We employ these
          technologies to:
        </p>
        <ul className="list-disc ml-6 mb-4 text-gray-300">
          <li className="mb-2">Validate task completion for rewarding user engagement;</li>
          <li className="mb-2">
            Attribution and billing with our Game Partners;
          </li>
          <li className="mb-2">Prevent and detect fraud;</li>
          <li className="mb-2">
            Deliver relevant promotions and measure marketing.
          </li>
        </ul>
        <p className="mb-4 text-gray-300">
          If you disable these technologies (where possible) via browser or device settings, you may experience
          limited functionality, especially regarding loyalty programs. For more information, see our Cookie
          Notice.
        </p>

        <hr className="my-6 border-gray-700" />

        <h2 id="contact-us" className="text-2xl font-semibold mt-6 mb-4">7. CONTACT US</h2>
        <p className="mb-4 text-gray-300">
          If you have questions unrelated to this Notice, please reach out to our customer support. If you
          wish to exercise any data protection rights or comment on our privacy practices, contact our data
          privacy office:
        </p>
        <address className="mb-4 text-gray-300">
          Data Privacy Officer
          <br />
          AppQwest
          <br />
          229 rue Saint-Honoré
          <br />
          75001 Paris
          <br />
          <a href="mailto:privacy@appqwest.com" className="text-blue-400 hover:underline">
            privacy@appqwest.com
          </a>
        </address>

        <hr className="my-6 border-gray-700" />

        <h2 id="annex-a" className="text-2xl font-semibold mt-6 mb-4">
          ANNEX A: REGION-SPECIFIC NOTICES
        </h2>
        <p className="mb-6 text-gray-300">
          This Annex provides additional region-specific disclosures or rights for residents of certain countries,
          states, or regions, such as the EEA, UK, Switzerland, the United States (including California, Colorado,
          Connecticut, and others), Canada, South Korea, and Japan. Please refer to the separate addenda for details
          applicable to your jurisdiction.
        </p>

        <hr className="my-6 border-gray-700" />

        <h2 id="annex-b" className="text-2xl font-semibold mt-6 mb-4">
          ANNEX B: TABLE FOR LEGAL PROCESSING ACTIVITIES
        </h2>
        <p className="mb-4 text-gray-300">
          The chart below provides examples of typical processing activities, associated data categories, and
          their legal bases under applicable laws.
        </p>
        <div className="overflow-x-auto mb-4">
          <table className="min-w-full border border-gray-600">
            <thead className="bg-gray-800">
              <tr>
                <th className="border border-gray-600 px-4 py-2">
                  Processing Purpose
                </th>
                <th className="border border-gray-600 px-4 py-2">
                  Categories of Data Used
                </th>
                <th className="border border-gray-600 px-4 py-2">
                  Legal Basis for Processing
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-600 px-4 py-2">
                  Account Creation &amp; Service Delivery
                </td>
                <td className="border border-gray-600 px-4 py-2">
                  Account Data (name, email, etc.)
                </td>
                <td className="border border-gray-600 px-4 py-2">
                  Performance of contract (to provide Services).
                  <br />
                  Legal obligations (if relevant).
                </td>
              </tr>
              <tr>
                <td className="border border-gray-600 px-4 py-2">
                  Fraud &amp; Security Detection
                </td>
                <td className="border border-gray-600 px-4 py-2">
                  Device Data, Usage Data, Gameplay Data, Account Data
                </td>
                <td className="border border-gray-600 px-4 py-2">
                  Legitimate interest in preventing misuse.
                  <br />
                  Consent where required by law.
                </td>
              </tr>
              <tr>
                <td className="border border-gray-600 px-4 py-2">
                  Personalizing Advertisements &amp; Promotions
                </td>
                <td className="border border-gray-600 px-4 py-2">
                  Usage Data, Device Data, Gameplay Data
                </td>
                <td className="border border-gray-600 px-4 py-2">
                  Legitimate interest in providing relevant content.
                  <br />
                  Consent where required by law.
                </td>
              </tr>
              <tr>
                <td className="border border-gray-600 px-4 py-2">
                  Rewards &amp; Promotions
                </td>
                <td className="border border-gray-600 px-4 py-2">
                  Account Data, Gameplay Data, Device Data, Location
                </td>
                <td className="border border-gray-600 px-4 py-2">
                  Performance of contract (to enable loyalty program and prize awarding).
                </td>
              </tr>
              <tr>
                <td className="border border-gray-600 px-4 py-2">
                  Marketing &amp; Community Building
                </td>
                <td className="border border-gray-600 px-4 py-2">
                  Device Data (advertising IDs), Usage Data
                </td>
                <td className="border border-gray-600 px-4 py-2">
                  Legitimate interest (marketing campaigns).
                  <br />
                  Consent where required by law.
                </td>
              </tr>
              <tr>
                <td className="border border-gray-600 px-4 py-2">Analytics &amp; Insights</td>
                <td className="border border-gray-600 px-4 py-2">
                  Usage Data, Gameplay Data
                </td>
                <td className="border border-gray-600 px-4 py-2">
                  Legitimate interest to improve Services.
                  <br />
                  Consent where required by law.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="text-sm text-gray-400">
          <em>
            Note: The above table is provided as an illustrative reference. Actual legal bases and data
            categories may vary depending on jurisdiction and specific Services provided.
          </em>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
