import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { db } from "../firebaseConfig";
import { collection, doc, getDocs, getDoc, setDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";

// Generates a random unique string for new qwests
const generateUniqueId = () => {
  return Math.random().toString(36).substr(2, 9);
};

const EditChallenge = () => {
  const { challengeId } = useParams(); // expecting challengeId to be the appId
  const [apps, setApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [qwests, setQwests] = useState([]);

  // Fetch apps for the dropdown selection
  useEffect(() => {
    const fetchApps = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "apps"));
        const allApps = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          appId: doc.id,
        }));
        setApps(allApps);
      } catch (error) {
        console.error("Error fetching apps:", error);
      }
    };

    fetchApps();
  }, []);

  // Fetch the existing challenge data using the challengeId from the URL
  useEffect(() => {
    const fetchChallenge = async () => {
      try {
        const challengeRef = doc(db, "challenges", challengeId);
        const challengeSnap = await getDoc(challengeRef);
        if (challengeSnap.exists()) {
          const challengeData = challengeSnap.data();
          // Transform each qwest's title and subText (stored as objects) into JSON strings
          // Also, include the maxTime field (if it exists) or default to an empty string
          const transformedQwests = (challengeData.qwests || []).map((qwest) => ({
            ...qwest,
            titleJSON: JSON.stringify(qwest.title),
            subTextJSON: JSON.stringify(qwest.subText),
            maxTime: qwest.maxTime ? qwest.maxTime.toString() : "",
          }));
          setQwests(transformedQwests);
          // Initially set the selected app using the stored appId.
          setSelectedApp({ appId: challengeData.appId });
        } else {
          console.error("Challenge not found");
        }
      } catch (error) {
        console.error("Error fetching challenge:", error);
      }
    };

    fetchChallenge();
  }, [challengeId]);

  // Once apps are loaded, update the selectedApp with full app details
  useEffect(() => {
    if (selectedApp && selectedApp.appId && apps.length > 0) {
      const fullAppData = apps.find((app) => app.appId === selectedApp.appId);
      if (fullAppData) {
        setSelectedApp(fullAppData);
      }
    }
  }, [apps, selectedApp]);

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  const handleAppSelection = (appId) => {
    const selectedAppData = apps.find((app) => app.appId === appId);
    if (!selectedAppData) {
      console.error("Selected app not found in the list.");
      return;
    }
    setSelectedApp(selectedAppData);
    setDropdownOpen(false);
  };

  const clearSelection = () => {
    setSelectedApp(null);
    setDropdownOpen(true);
  };

  const handleQwestChange = (index, field, value) => {
    const updatedQwests = [...qwests];
    updatedQwests[index][field] = value;
    setQwests(updatedQwests);
  };

  const addQwest = () => {
    setQwests([
      ...qwests,
      {
        qwestId: generateUniqueId(),
        titleJSON: '{"en": "", "fr": ""}',
        subTextJSON: '{"en": "", "fr": ""}',
        value: "",
        partnerId: "",
        maxTime: "", // optional maximum time for the new qwest
      },
    ]);
  };

  const saveChallenge = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      alert("You need to be logged in to save a challenge.");
      return;
    }
    if (!selectedApp) {
      alert("Please select an app before saving the challenge.");
      return;
    }
    if (!selectedApp.appId) {
      alert("Selected app ID is missing.");
      return;
    }
    // Validate and parse JSON for each qwest
    const parsedQwests = [];
    for (const qwest of qwests) {
      if (!qwest.value || !qwest.partnerId) {
        alert("Each Qwest must have a value and a partner ID.");
        return;
      }
      try {
        const title = JSON.parse(qwest.titleJSON);
        const subText = JSON.parse(qwest.subTextJSON);
        parsedQwests.push({
          qwestId: qwest.qwestId,
          title,
          subText,
          value: parseInt(qwest.value, 10),
          partnerId: qwest.partnerId,
          ...(qwest.maxTime && { maxTime: parseInt(qwest.maxTime, 10) }),
        });
      } catch (error) {
        alert("Invalid JSON in title or subtext for one of the qwests.");
        return;
      }
    }

    try {
      const challengeDocRef = doc(db, "challenges", selectedApp.appId);
      const challengeData = {
        userId: user.uid,
        appId: selectedApp.appId,
        qwests: parsedQwests,
      };

      await setDoc(challengeDocRef, challengeData);
      alert("Challenge updated successfully!");
    } catch (error) {
      console.error("Error updating challenge:", error);
      alert("Failed to update challenge.");
    }
  };

  const filteredApps = apps.filter((app) =>
    app.title?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Edit Challenge</h1>

      {/* App Selection */}
      <div className="mb-4 relative">
        <h2 className="font-semibold mb-2">Application</h2>
        <div className="flex items-center">
          <div
            className="w-full px-4 py-2 border rounded bg-white cursor-pointer"
            onClick={toggleDropdown}
          >
            {selectedApp ? selectedApp.title : "Select an App"}
          </div>
          {selectedApp && (
            <button
              className="ml-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
              onClick={clearSelection}
            >
              Clear
            </button>
          )}
        </div>
        {dropdownOpen && (
          <div className="absolute left-0 mt-1 w-full border rounded bg-white shadow-lg max-h-60 overflow-y-auto z-20">
            <div className="p-2">
              <input
                type="text"
                placeholder="Search apps..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            {filteredApps.map((app) => (
              <div
                key={app.appId}
                onClick={() => handleAppSelection(app.appId)}
                className={`flex items-center p-2 cursor-pointer hover:bg-gray-200 ${
                  selectedApp?.appId === app.appId ? "bg-gray-200" : ""
                }`}
              >
                <img
                  src={app.icon || "https://via.placeholder.com/40"}
                  alt={app.title}
                  className="w-10 h-10 mr-2"
                />
                <div>
                  <p className="font-semibold">{app.title}</p>
                  <p className="text-sm text-gray-500">{app.developer}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Qwest Editing */}
      <h2 className="font-semibold mb-2">Qwests</h2>
      {qwests.map((qwest, index) => (
        <div key={qwest.qwestId} className="mb-4 grid grid-cols-5 gap-4">
          <textarea
            placeholder='Qwest Title translations (e.g., {"en": "Title", "fr": "Titre"})'
            value={qwest.titleJSON}
            onChange={(e) => handleQwestChange(index, "titleJSON", e.target.value)}
            className="px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <textarea
            placeholder='Sub-text translations (e.g., {"en": "Sub-text", "fr": "Sous-texte"})'
            value={qwest.subTextJSON}
            onChange={(e) => handleQwestChange(index, "subTextJSON", e.target.value)}
            className="px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="number"
            placeholder="Value"
            value={qwest.value}
            onChange={(e) => handleQwestChange(index, "value", e.target.value)}
            className="px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="text"
            placeholder="Partner ID"
            value={qwest.partnerId}
            onChange={(e) => handleQwestChange(index, "partnerId", e.target.value)}
            className="px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="number"
            placeholder="Max Time (minutes, optional)"
            value={qwest.maxTime}
            onChange={(e) => handleQwestChange(index, "maxTime", e.target.value)}
            className="px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      ))}

      {/* Add Qwest Button */}
      <button
        onClick={addQwest}
        className="mb-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
      >
        + Add Qwest
      </button>

      {/* Save Challenge Button */}
      <div className="mt-6">
        <button
          onClick={saveChallenge}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Save Challenge
        </button>
      </div>
    </div>
  );
};

export default EditChallenge;
