import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig"; // Adjust the path if needed
import {
  collection,
  query,
  orderBy,
  getDocs,
  doc,
  getDoc,
  addDoc
} from "firebase/firestore";

// Helper function to extract localized string
const getLocalizedValue = (value) => {
  if (value && typeof value === "object") {
    const lang = navigator.language.slice(0, 2); // e.g., "en"
    return value[lang] || value["en"] || "";
  }
  return value || "";
};

const AppAndQwestSelector = () => {
  // Apps-related state
  const [apps, setApps] = useState([]);
  const [appSearch, setAppSearch] = useState("");
  const [filteredApps, setFilteredApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);
  const [loadingApps, setLoadingApps] = useState(false);

  // Qwests-related state
  const [qwests, setQwests] = useState([]);
  const [qwestSearch, setQwestSearch] = useState("");
  const [filteredQwests, setFilteredQwests] = useState([]);
  const [selectedQwest, setSelectedQwest] = useState(null);
  const [loadingQwests, setLoadingQwests] = useState(false);

  // Promotional message text
  const [promoText, setPromoText] = useState("");

  const [error, setError] = useState("");

  // Load apps from Firestore "apps" collection on component mount
  useEffect(() => {
    const loadApps = async () => {
      setLoadingApps(true);
      try {
        const appsQuery = query(collection(db, "apps"), orderBy("title"));
        const querySnapshot = await getDocs(appsQuery);
        const appsList = querySnapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setApps(appsList);
        setFilteredApps(appsList);
      } catch (err) {
        console.error("Error loading apps:", err);
        setError("Error loading apps.");
      } finally {
        setLoadingApps(false);
      }
    };
    loadApps();
  }, []);

  // Filter apps based on the search query
  useEffect(() => {
    setFilteredApps(
      apps.filter(
        (app) =>
          app.title?.toLowerCase().includes(appSearch.toLowerCase()) ||
          app.developer?.toLowerCase().includes(appSearch.toLowerCase())
      )
    );
  }, [appSearch, apps]);

  // When an app is selected, load its qwests from the "challenges" collection
  useEffect(() => {
    if (selectedApp) {
      const loadQwests = async () => {
        setLoadingQwests(true);
        try {
          // Assumes the challenges document ID equals the app's appId or bundleId
          const challengeDocId = selectedApp.appId || selectedApp.bundleId;
          const challengeDocRef = doc(db, "challenges", challengeDocId);
          const challengeDocSnap = await getDoc(challengeDocRef);
          if (challengeDocSnap.exists()) {
            const challengeData = challengeDocSnap.data();
            setQwests(challengeData.qwests || []);
            setFilteredQwests(challengeData.qwests || []);
          } else {
            setQwests([]);
            setFilteredQwests([]);
          }
          // Reset selected qwest when a new app is selected
          setSelectedQwest(null);
        } catch (err) {
          console.error("Error loading qwests:", err);
          setError("Error loading qwests.");
        } finally {
          setLoadingQwests(false);
        }
      };
      loadQwests();
    } else {
      // Clear qwests if no app is selected
      setQwests([]);
      setFilteredQwests([]);
      setSelectedQwest(null);
    }
  }, [selectedApp]);

  // Filter qwests based on the qwest search query using localized title
  useEffect(() => {
    setFilteredQwests(
      qwests.filter((qwest) =>
        getLocalizedValue(qwest.title)
          .toLowerCase()
          .includes(qwestSearch.toLowerCase())
      )
    );
  }, [qwestSearch, qwests]);

  // Function to send the promotional message to Firestore
  const handleSendMessage = async () => {
    if (!selectedApp || !selectedQwest) {
      alert("Please select an app and a qwest first.");
      return;
    }
    if (!promoText.trim()) {
      alert("Promotional message text cannot be empty.");
      return;
    }
    try {
      const promoMessage = {
        isPromotion: true,
        text: promoText,
        appTitle: selectedApp.title,
        appIconUrl: selectedApp.icon,
        // Use the localized value for the qwest title
        qwestTitle: getLocalizedValue(selectedQwest.title),
        reward: selectedQwest.value,
        link: selectedApp.url || "", // Use app url if available
        userId: "back_office", // Special ID for back office messages
        senderInitials: "BO", // Initials for back office
        // Save the timestamp as Unix format (milliseconds)
        timestamp: Date.now(),
      };
      // Save to the subcollection "messages" under the document "public" in the "chats" collection.
      await addDoc(collection(db, "chats", "public", "messages"), promoMessage);
      alert("Promotional message sent successfully.");
      // Optionally, reset the promotional text and selected states
      setPromoText("");
      setSelectedApp(null);
      setSelectedQwest(null);
    } catch (err) {
      console.error("Error sending promo message:", err);
      alert("Error sending promotional message: " + err.message);
    }
  };

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Select an App</h1>
      <input
        type="text"
        placeholder="Search apps..."
        value={appSearch}
        onChange={(e) => setAppSearch(e.target.value)}
        className="w-full px-4 py-2 border rounded mb-4"
      />
      {loadingApps ? (
        <p>Loading apps...</p>
      ) : (
        <ul className="border rounded p-4 mb-8">
          {filteredApps.map((app) => (
            <li
              key={app.id}
              onClick={() => setSelectedApp(app)}
              className={`p-2 cursor-pointer hover:bg-gray-100 ${
                selectedApp && selectedApp.id === app.id ? "bg-gray-200" : ""
              }`}
            >
              {app.title} - {app.developer}
            </li>
          ))}
        </ul>
      )}

      {selectedApp && (
        <div>
          <h2 className="text-xl font-bold mb-4">
            Select a Qwest for {selectedApp.title}
          </h2>
          <input
            type="text"
            placeholder="Search qwests..."
            value={qwestSearch}
            onChange={(e) => setQwestSearch(e.target.value)}
            className="w-full px-4 py-2 border rounded mb-4"
          />
          {loadingQwests ? (
            <p>Loading qwests...</p>
          ) : (
            <ul className="border rounded p-4">
              {filteredQwests.length > 0 ? (
                filteredQwests.map((q, index) => (
                  <li
                    key={index}
                    onClick={() => setSelectedQwest(q)}
                    className={`p-2 cursor-pointer hover:bg-gray-100 border-b last:border-0 ${
                      selectedQwest && selectedQwest.qwestId === q.qwestId
                        ? "bg-gray-200"
                        : ""
                    }`}
                  >
                    {getLocalizedValue(q.title)} (Reward: {q.value})
                  </li>
                ))
              ) : (
                <p>No qwests found for this app.</p>
              )}
            </ul>
          )}
        </div>
      )}

      {/* Preview the message when both an app and a qwest are selected */}
      {selectedApp && selectedQwest && (
        <div className="mt-8 p-4 border rounded bg-gray-50">
          <h3 className="text-xl font-semibold mb-2">Message Preview</h3>
          <div className="flex items-center">
            <img
              src={selectedApp.icon || "https://via.placeholder.com/100"}
              alt={selectedApp.title}
              className="w-16 h-16 mr-4"
            />
            <div>
              <p>
                <strong>{selectedApp.title}</strong> by {selectedApp.developer}
              </p>
              <p>
                Challenge:{" "}
                <strong>{getLocalizedValue(selectedQwest.title)}</strong> - Reward:{" "}
                <strong>{selectedQwest.value}</strong>
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Text area to edit the promotional message */}
      {selectedApp && selectedQwest && (
        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-2">
            Edit Promotional Message
          </h3>
          <textarea
            placeholder="Enter your promotional message here..."
            value={promoText}
            onChange={(e) => setPromoText(e.target.value)}
            className="w-full border rounded p-2"
            rows="4"
          />
          <button
            onClick={handleSendMessage}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
          >
            Send Message
          </button>
        </div>
      )}

      {error && <p className="text-red-500">{error}</p>}
    </div>
  );
};

export default AppAndQwestSelector;
