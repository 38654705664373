import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { db } from "../firebaseConfig";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";

// Define generateUniqueId function first
const generateUniqueId = () => {
  return Math.random().toString(36).substr(2, 9); // Generates a random string
};

const CreateChallenge = () => {
  const [apps, setApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  
  // Initialize qwests with an added partnerId field, JSON strings for translations, and a maxTime field
  const [qwests, setQwests] = useState([
    { 
      qwestId: generateUniqueId(), 
      titleJSON: '{"en": "", "fr": ""}', 
      subTextJSON: '{"en": "", "fr": ""}', 
      value: "", 
      partnerId: "",
      maxTime: "" // optional maximum time (minutes) for this qwest
    }
  ]);

  useEffect(() => {
    const fetchApps = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "apps"));
        const allApps = querySnapshot.docs.map((doc) => ({ ...doc.data(), appId: doc.id }));
        setApps(allApps);
      } catch (error) {
        console.error("Error fetching apps:", error);
      }
    };

    fetchApps();
  }, []);

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  const handleAppSelection = (appId) => {
    console.log("App clicked with appId:", appId);
    const selectedAppData = apps.find((app) => app.appId === appId);
    if (!selectedAppData) {
      console.error("Selected app not found in the list.");
      return;
    }
    setSelectedApp(selectedAppData);
    setDropdownOpen(false);
    console.log("Selected App:", selectedAppData);
  };

  const clearSelection = () => {
    setSelectedApp(null);
    setDropdownOpen(true);
  };

  const handleQwestChange = (index, field, value) => {
    const updatedQwests = [...qwests];
    updatedQwests[index][field] = value;
    setQwests(updatedQwests);
  };

  const addQwest = () => {
    setQwests([
      ...qwests,
      { 
        qwestId: generateUniqueId(), 
        titleJSON: '{"en": "", "fr": ""}', 
        subTextJSON: '{"en": "", "fr": ""}', 
        value: "", 
        partnerId: "",
        maxTime: "" // optional maximum time for the new qwest
      }
    ]);
  };

  const saveChallenge = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      alert("You need to be logged in to save a challenge.");
      return;
    }

    if (!selectedApp) {
      alert("Please select an app before saving the challenge.");
      return;
    }

    if (!selectedApp.appId) {
      console.error("Error: selectedApp.appId is undefined");
      alert("Selected app ID is missing.");
      return;
    }

    // Validate and parse JSON for each qwest
    const parsedQwests = [];
    for (const qwest of qwests) {
      if (!qwest.value || !qwest.partnerId) {
        alert("Each Qwest must have a value and a partner ID.");
        return;
      }
      try {
        const title = JSON.parse(qwest.titleJSON);
        const subText = JSON.parse(qwest.subTextJSON);
        parsedQwests.push({
          qwestId: qwest.qwestId,
          title,
          subText,
          value: parseInt(qwest.value, 10),
          partnerId: qwest.partnerId,
          ...(qwest.maxTime && { maxTime: parseInt(qwest.maxTime, 10) })
        });
      } catch (error) {
        alert("Invalid JSON in title or subtext for one of the qwests.");
        return;
      }
    }

    try {
      const challengeDocRef = doc(db, "challenges", selectedApp.appId);
      const challengeData = {
        userId: user.uid,
        appId: selectedApp.appId,
        qwests: parsedQwests,
      };

      await setDoc(challengeDocRef, challengeData);
      alert("Challenge saved successfully!");
    } catch (error) {
      console.error("Error saving challenge:", error);
      alert("Failed to save challenge.");
    }
  };

  const filteredApps = apps.filter((app) =>
    app.title?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  console.log("Filtered Apps:", filteredApps);

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Create a New Challenge</h1>

      {/* App Selection */}
      <div className="mb-4 relative">
        <h2 className="font-semibold mb-2">Application</h2>
        <div className="flex items-center">
          <div
            className="w-full px-4 py-2 border rounded bg-white cursor-pointer"
            onClick={toggleDropdown}
          >
            {selectedApp ? selectedApp.title : "Select an App"}
          </div>
          {selectedApp && (
            <button
              className="ml-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
              onClick={clearSelection}
            >
              Clear
            </button>
          )}
        </div>
        {dropdownOpen && (
          <div className="absolute left-0 mt-1 w-full border rounded bg-white shadow-lg max-h-60 overflow-y-auto z-20">
            <div className="p-2">
              <input
                type="text"
                placeholder="Search apps..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            {filteredApps.map((app) => (
              <div
                key={app.appId}
                onClick={() => handleAppSelection(app.appId)}
                className={`flex items-center p-2 cursor-pointer hover:bg-gray-200 ${
                  selectedApp?.appId === app.appId ? "bg-gray-200" : ""
                }`}
              >
                <img
                  src={app.icon || "https://via.placeholder.com/40"}
                  alt={app.title}
                  className="w-10 h-10 mr-2"
                />
                <div>
                  <p className="font-semibold">{app.title}</p>
                  <p className="text-sm text-gray-500">{app.developer}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Qwest Creation */}
      <h2 className="font-semibold mb-2">Qwests</h2>
      {qwests.map((qwest, index) => (
        <div key={qwest.qwestId} className="mb-4 grid grid-cols-5 gap-4">
          <textarea
            placeholder='Qwest Title translations (e.g., {"en": "Title", "fr": "Titre"})'
            value={qwest.titleJSON}
            onChange={(e) => handleQwestChange(index, "titleJSON", e.target.value)}
            className="px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <textarea
            placeholder='Sub-text translations (e.g., {"en": "Sub-text", "fr": "Sous-texte"})'
            value={qwest.subTextJSON}
            onChange={(e) => handleQwestChange(index, "subTextJSON", e.target.value)}
            className="px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="number"
            placeholder="Value"
            value={qwest.value}
            onChange={(e) => handleQwestChange(index, "value", e.target.value)}
            className="px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="text"
            placeholder="Partner ID"
            value={qwest.partnerId}
            onChange={(e) => handleQwestChange(index, "partnerId", e.target.value)}
            className="px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="number"
            placeholder="Max Time (minutes, optional)"
            value={qwest.maxTime}
            onChange={(e) => handleQwestChange(index, "maxTime", e.target.value)}
            className="px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      ))}

      {/* Add Qwest Button */}
      <button
        onClick={addQwest}
        className="mb-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
      >
        + Add Qwest
      </button>

      {/* Save Challenge Button */}
      <div className="mt-6">
        <button
          onClick={saveChallenge}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Save Challenge
        </button>
      </div>
    </div>
  );
};

export default CreateChallenge;
