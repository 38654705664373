import React, { useState } from "react";
import Papa from "papaparse";

const NotificationPage = () => {
  // CSV file state and parsed tokens
  const [csvFile, setCsvFile] = useState(null);
  const [setTokens] = useState([]);

  // Notification fields (notification object) – icon is now fixed
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [clickAction, setClickAction] = useState("");
  const [sound, setSound] = useState("");
  const [tag, setTag] = useState("");
  const [color, setColor] = useState("");

  // Custom data fields (data object) as a dynamic list
  const [customData, setCustomData] = useState([{ key: "", value: "" }]);

  // Other states for loading and response messages
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // Handler for CSV file selection
  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  // Parse CSV file to extract tokens (assumes one token per line)
  const parseCSV = () => {
    return new Promise((resolve, reject) => {
      if (!csvFile) {
        reject("No CSV file uploaded.");
      }
      Papa.parse(csvFile, {
        complete: (results) => {
          const parsedTokens = results.data
            .flat()
            .filter((token) => token && token.trim() !== "");
          resolve(parsedTokens);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  // Handlers to manage dynamic custom data fields
  const addCustomDataField = () => {
    setCustomData([...customData, { key: "", value: "" }]);
  };

  const updateCustomDataField = (index, field, value) => {
    const updated = [...customData];
    updated[index][field] = value;
    setCustomData(updated);
  };

  const removeCustomDataField = (index) => {
    const updated = [...customData];
    updated.splice(index, 1);
    setCustomData(updated);
  };

  // Handle form submission: parse CSV, build payload, and send notification
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const parsedTokens = await parseCSV();
      setTokens(parsedTokens);
      if (parsedTokens.length === 0) {
        alert("No valid tokens found in the CSV file.");
        return;
      }

      // Build the notification payload with a fixed icon value.
      const notificationPayload = { title, body };
        if (clickAction) notificationPayload.click_action = clickAction;
        if (sound) notificationPayload.sound = sound;
        if (tag) notificationPayload.tag = tag;
        if (color) notificationPayload.color = color;

      // Build the custom data payload by converting the array to an object
      const dataPayload = {};
      customData.forEach(({ key, value }) => {
        if (key.trim() !== "") {
          dataPayload[key] = value;
        }
      });

      const payload = {
        tokens: parsedTokens,
        notification: notificationPayload,
        data: dataPayload,
      };

      setLoading(true);

      // Replace with your backend endpoint URL that sends notifications
      const response = await fetch(
        "https://us-central1-appqwest-57563.cloudfunctions.net/sendNotification",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );
      const resData = await response.json();
      setResponseMessage(`Notification sent successfully: ${JSON.stringify(resData)}`);
    } catch (error) {
      console.error("Error:", error);
      setResponseMessage(`Error sending notification: ${error.toString()}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-center">Send Notification</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block mb-2 font-semibold">
            CSV File (one token per line):
          </label>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            className="block w-full text-gray-700 border border-gray-300 rounded-md p-2"
          />
        </div>
        <hr className="my-4" />
        <h2 className="text-2xl font-semibold mb-4">Notification Settings</h2>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <label className="block font-semibold mb-1">Title:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              className="w-full border border-gray-300 p-2 rounded-md"
            />
          </div>
          <div>
            <label className="block font-semibold mb-1">Body:</label>
            <input
              type="text"
              value={body}
              onChange={(e) => setBody(e.target.value)}
              required
              className="w-full border border-gray-300 p-2 rounded-md"
            />
          </div>
          <div>
            <label className="block font-semibold mb-1">Click Action:</label>
            <input
              type="text"
              value={clickAction}
              onChange={(e) => setClickAction(e.target.value)}
              placeholder="Action or URL on click"
              className="w-full border border-gray-300 p-2 rounded-md"
            />
          </div>
          <div>
            <label className="block font-semibold mb-1">Sound:</label>
            <input
              type="text"
              value={sound}
              onChange={(e) => setSound(e.target.value)}
              placeholder="Sound file name"
              className="w-full border border-gray-300 p-2 rounded-md"
            />
          </div>
          <div>
            <label className="block font-semibold mb-1">Tag:</label>
            <input
              type="text"
              value={tag}
              onChange={(e) => setTag(e.target.value)}
              placeholder="Notification tag (optional)"
              className="w-full border border-gray-300 p-2 rounded-md"
            />
          </div>
          <div>
            <label className="block font-semibold mb-1">Color:</label>
            <input
              type="text"
              value={color}
              onChange={(e) => setColor(e.target.value)}
              placeholder="Accent color (optional)"
              className="w-full border border-gray-300 p-2 rounded-md"
            />
          </div>
        </div>
        <hr className="my-4" />
        <h2 className="text-2xl font-semibold mb-4">Custom Data Fields</h2>
        {customData.map((field, index) => (
          <div key={index} className="flex items-center space-x-2 mb-2">
            <input
              type="text"
              placeholder="Key"
              value={field.key}
              onChange={(e) => updateCustomDataField(index, "key", e.target.value)}
              className="flex-1 border border-gray-300 p-2 rounded-md"
            />
            <input
              type="text"
              placeholder="Value"
              value={field.value}
              onChange={(e) => updateCustomDataField(index, "value", e.target.value)}
              className="flex-1 border border-gray-300 p-2 rounded-md"
            />
            <button
              type="button"
              onClick={() => removeCustomDataField(index)}
              className="px-3 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={addCustomDataField}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Add Data Field
        </button>
        <hr className="my-4" />
        <button
          type="submit"
          disabled={loading}
          className="w-full px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
        >
          {loading ? "Sending..." : "Send Notification"}
        </button>
      </form>
      {responseMessage && <p className="mt-4 text-center">{responseMessage}</p>}
    </div>
  );
};

export default NotificationPage;
