import React from "react";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";

const ConsolePage = () => {
    const { user, loading } = useAuth();
    const navigate = useNavigate();

    if (loading) {
        return <h1>Loading...</h1>;
    }

    if (!user) {
        navigate("/");
        return null;
    }

    return (
        <div className="flex h-screen">
            <div className="flex-1 bg-gray-100">
                <div className="p-8">
                    <h1 className="text-2xl font-bold">Welcome to the Console</h1>
                    <p>
                        Hello, {user.email}. Explore the menu on the left to navigate.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ConsolePage;
