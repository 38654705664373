import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import {
    doc,
    getDoc,
    updateDoc,
    addDoc,
    setDoc,
    collection,
    query,
    where,
    serverTimestamp,
    getDocs,
} from "firebase/firestore";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";

const MyApps = () => {
    const [bundleId, setBundleId] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [apps, setApps] = useState([]);
    const [filteredApps, setFilteredApps] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const { user } = useAuth();
    const navigate = useNavigate();

    const handleInputChange = (e) => setBundleId(e.target.value);

    const handleSearchChange = (e) => {
        const queryText = e.target.value.toLowerCase();
        setSearchQuery(queryText);
        setFilteredApps(
            apps.filter((app) =>
                app.title?.toLowerCase().includes(queryText) ||
                app.developer?.toLowerCase().includes(queryText)
            )
        );
    };

    const handleValidate = async () => {
        if (!bundleId.trim()) return;

        setIsLoading(true);
        setError("");

        try {
            // Fetch the user document to get the companyId
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnap = await getDoc(userDocRef);
            if (!userDocSnap.exists()) {
                throw new Error("User not found.");
            }
            const { companyId } = userDocSnap.data();

            // Fetch app data from the `apps` collection or external API
            const appDocRef = doc(db, "apps", bundleId);
            const appDocSnap = await getDoc(appDocRef);

            let appData;
            if (appDocSnap.exists()) {
                console.log("App data exists in Firestore:", appDocSnap.data());
                appData = appDocSnap.data();
            } else {
                // Call the Cloud Function to fetch and save app data
                const response = await fetch(
                    `https://us-central1-appqwest-57563.cloudfunctions.net/getAppData?bundleId=${bundleId}`
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch app details from Cloud Function.");
                }

                appData = await response.json();

                // Save the fetched app data to Firestore for future use
                await setDoc(appDocRef, appData);
                console.log("Fetched and saved app data:", appData);
            }

            // Parse and validate app data
            const parsedAppData = {
                bundleId: appData.appId || appData.bundleId || "Unknown Bundle ID",
                developer: appData.developer || "Unknown Developer",
                icon: appData.icon || "https://via.placeholder.com/100",
                title: appData.title || "Unknown Title",
            };

            if (
                !parsedAppData.bundleId ||
                !parsedAppData.developer ||
                !parsedAppData.icon ||
                !parsedAppData.title
            ) {
                throw new Error("Incomplete app data received. Please check the Bundle ID.");
            }

            // Check if a document exists for the company
            const appListQuery = query(
                collection(db, "appList"),
                where("companyId", "==", companyId)
            );
            const querySnapshot = await getDocs(appListQuery);

            if (!querySnapshot.empty) {
                // Update the existing document
                const appListDocRef = querySnapshot.docs[0].ref; // Assuming one document per company
                const existingData = querySnapshot.docs[0].data();

                const updatedApps = {
                    ...existingData.apps, // Retain existing apps
                    [parsedAppData.bundleId]: parsedAppData, // Add new app keyed by its bundle ID
                };

                await updateDoc(appListDocRef, {
                    apps: updatedApps,
                    updatedAt: serverTimestamp(),
                });
            } else {
                // Create a new document for the company
                const appListRef = collection(db, "appList");
                await addDoc(appListRef, {
                    companyId,
                    addedBy: user.uid,
                    apps: { [parsedAppData.bundleId]: parsedAppData }, // Store apps as a map
                    createdAt: serverTimestamp(),
                });
            }

            alert("App has been validated and added to your app list!");

            // Reload company apps
            await loadCompanyApps(companyId);
            setBundleId("");
        } catch (error) {
            console.error("Error validating app:", error);
            setError(error.message || "An error occurred while validating the app.");
        } finally {
            setIsLoading(false);
        }
    };

    const loadCompanyApps = async (companyId) => {
        try {
            const companyAppsQuery = query(
                collection(db, "appList"),
                where("companyId", "==", companyId)
            );
            const querySnapshot = await getDocs(companyAppsQuery);

            const companyApps = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                const appsMap = data.apps || {}; // Ensure apps exist
                Object.values(appsMap).forEach((app) => {
                    companyApps.push(app); // Flatten each app object into the array
                });
            });

            setApps(companyApps);
            setFilteredApps(companyApps);
        } catch (error) {
            console.error("Error loading company apps:", error);
        }
    };

    // Navigate to the app details page when an app is clicked.
    const handleAppClick = (app) => {
        // Assuming your details route uses the app's bundleId.
        navigate(`/app-details/${app.bundleId}`);
    };

    useEffect(() => {
        const initializeApps = async () => {
            if (!user) return;

            try {
                const userDocRef = doc(db, "users", user.uid);
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    const { companyId } = userDocSnap.data();
                    await loadCompanyApps(companyId);
                }
            } catch (error) {
                console.error("Error initializing apps:", error);
            }
        };

        initializeApps();
    }, [user]);

    return (
        <div className="p-8 pt-24">
            <h1 className="text-2xl font-bold mb-4">My Apps</h1>
            <p className="mb-2 text-gray-700">
                Enter a Bundle ID to validate and fetch app info.
            </p>
            <div className="flex items-center space-x-4">
                <input
                    type="text"
                    value={bundleId}
                    onChange={handleInputChange}
                    placeholder="Enter Bundle ID"
                    className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                    onClick={handleValidate}
                    className={`px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition ${
                        isLoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={isLoading}
                >
                    {isLoading ? "Processing..." : "Validate"}
                </button>
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>}

            <div className="mt-6">
                <p className="mb-2 text-gray-700">
                    Search for previously added apps:
                </p>
                <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search apps by title or developer"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            </div>

            <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {filteredApps.map((app, index) => (
                    <div
                        key={index}
                        onClick={() => handleAppClick(app)}
                        className="cursor-pointer bg-white shadow-md rounded-lg p-4 flex flex-col items-center text-center"
                    >
                        <img
                            src={app.icon || "https://via.placeholder.com/100"}
                            alt={`${app.title} icon`}
                            className="w-16 h-16 mb-4"
                        />
                        <h3 className="text-lg font-semibold">{app.title}</h3>
                        <p className="text-sm text-gray-600">{app.developer}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MyApps;
