import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import {
    collection,
    query,
    getDocs,
    doc,
    getDoc,
    setDoc,
} from 'firebase/firestore';

const Appranking = () => {
    const [apps, setApps] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredApps, setFilteredApps] = useState([]);
    const [rankedApps, setRankedApps] = useState([]);
    const [appOfTheDay, setAppOfTheDay] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    // Fetch apps and saved data from Firebase
    useEffect(() => {
        const fetchAppsAndRanking = async () => {
            try {
                // Fetch all apps
                const appsRef = collection(db, 'apps');
                const appsQuery = query(appsRef);
                const querySnapshot = await getDocs(appsQuery);
                const fetchedApps = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setApps(fetchedApps);
                setFilteredApps(fetchedApps);

                // Fetch saved Top 10 Ranking
                const rankingRef = doc(db, 'app_ranking', 'top10');
                const rankingSnap = await getDoc(rankingRef);
                if (rankingSnap.exists()) {
                    const { ranking } = rankingSnap.data();
                    const rankedAppsData = fetchedApps.filter((app) =>
                        ranking.includes(app.id)
                    );
                    setRankedApps(rankedAppsData);
                }

                // Fetch saved "App of the Day"
                const appOfTheDayRef = doc(db, 'app_ranking', 'app_of_the_day');
                const appOfTheDaySnap = await getDoc(appOfTheDayRef);
                if (appOfTheDaySnap.exists()) {
                    const { app: appId } = appOfTheDaySnap.data();
                    const appOfTheDayData = fetchedApps.find((app) => app.id === appId);
                    setAppOfTheDay(appOfTheDayData || null);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchAppsAndRanking();
    }, []);

    // Handle search input
    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        setFilteredApps(
            apps.filter(
                (app) =>
                    app.title.toLowerCase().includes(query) ||
                    app.developer.toLowerCase().includes(query)
            )
        );
    };

    // Add app to ranked list
    const addAppToRanking = (app) => {
        if (rankedApps.length >= 10) {
            setErrorMessage('You can only rank up to 10 apps.');
            return;
        }
        if (rankedApps.find((rankedApp) => rankedApp.id === app.id)) return; // Avoid duplicates
        setErrorMessage('');
        setRankedApps([...rankedApps, app]);
    };

    // Remove app from ranked list
    const removeAppFromRanking = (index) => {
        const updatedRankedApps = [...rankedApps];
        updatedRankedApps.splice(index, 1);
        setRankedApps(updatedRankedApps);
    };

    // Move app up in the ranking
    const moveUp = (index) => {
        if (index === 0) return;
        const updatedRankedApps = [...rankedApps];
        [updatedRankedApps[index - 1], updatedRankedApps[index]] = [
            updatedRankedApps[index],
            updatedRankedApps[index - 1],
        ];
        setRankedApps(updatedRankedApps);
    };

    // Move app down in the ranking
    const moveDown = (index) => {
        if (index === rankedApps.length - 1) return;
        const updatedRankedApps = [...rankedApps];
        [updatedRankedApps[index + 1], updatedRankedApps[index]] = [
            updatedRankedApps[index],
            updatedRankedApps[index + 1],
        ];
        setRankedApps(updatedRankedApps);
    };

    // Select "App of the Day"
    const selectAppOfTheDay = (app) => {
        setAppOfTheDay(app.id === appOfTheDay?.id ? null : app);
    };

    // Save ranking and "App of the Day" to Firebase
    const saveRanking = async () => {
        try {
            const rankingRef = doc(db, 'app_ranking', 'top10');
            const appOfTheDayRef = doc(db, 'app_ranking', 'app_of_the_day');

            // Save ranking
            const bundleIds = rankedApps.map((app) => app.id);
            await setDoc(rankingRef, { ranking: bundleIds });

            // Save "App of the Day"
            if (appOfTheDay) {
                await setDoc(appOfTheDayRef, { app: appOfTheDay.id });
            }

            alert('Ranking and App of the Day saved successfully!');
        } catch (error) {
            console.error('Error saving ranking or App of the Day:', error);
            alert('Failed to save data.');
        }
    };

    return (
        <div className="flex h-screen">
            {/* Left Panel */}
            <div className="w-1/3 bg-gray-100 p-4 overflow-y-auto">
                <h2 className="text-lg font-bold mb-4">Available Apps</h2>
                <input
                    type="text"
                    placeholder="Search apps..."
                    value={searchQuery}
                    onChange={handleSearch}
                    className="w-full mb-4 p-2 border border-gray-300 rounded"
                />
                <ul>
                    {filteredApps.map((app) => (
                        <li
                            key={app.id}
                            className={`p-2 border-b border-gray-300 flex items-center justify-between hover:bg-gray-200 ${
                                appOfTheDay?.id === app.id
                                    ? 'bg-yellow-100 border-yellow-500'
                                    : ''
                            }`}
                        >
                            <div
                                className="flex items-center cursor-pointer"
                                onClick={() => addAppToRanking(app)}
                            >
                                <img
                                    src={app.icon}
                                    alt={app.title}
                                    className="w-8 h-8 mr-4"
                                />
                                <div>
                                    <p className="font-semibold">
                                        {app.title}{' '}
                                        {appOfTheDay?.id === app.id && (
                                            <span className="text-sm text-yellow-600 font-bold">
                                                (App of the Day)
                                            </span>
                                        )}
                                    </p>
                                    <p className="text-sm text-gray-600">
                                        {app.developer}
                                    </p>
                                </div>
                            </div>
                            <button
                                onClick={() => selectAppOfTheDay(app)}
                                className={`text-yellow-500 text-lg ${
                                    appOfTheDay?.id === app.id
                                        ? 'font-bold'
                                        : 'opacity-50'
                                }`}
                            >
                                ★
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Right Panel */}
            <div className="w-2/3 bg-white p-4">
                <h2 className="text-xl font-bold mb-4 text-blue-500">Top 1 to 10 Ranking</h2>
                {errorMessage && (
                    <p className="text-red-500 mb-4">{errorMessage}</p>
                )}
                <ul className="space-y-2">
                    {rankedApps.map((app, index) => (
                        <li
                            key={app.id}
                            className="p-2 border border-gray-300 bg-gray-50 rounded flex items-center justify-between"
                        >
                            <div className="flex items-center">
                                <span className="font-bold text-gray-700 mr-4">{index + 1}.</span>
                                <img
                                    src={app.icon}
                                    alt={app.title}
                                    className="w-8 h-8 mr-4"
                                />
                                <p>{app.title}</p>
                            </div>
                            <div className="flex space-x-2">
                                <button
                                    className="text-blue-500 text-sm hover:underline"
                                    onClick={() => moveUp(index)}
                                >
                                    ↑
                                </button>
                                <button
                                    className="text-blue-500 text-sm hover:underline"
                                    onClick={() => moveDown(index)}
                                >
                                    ↓
                                </button>
                                <button
                                    className="text-red-500 text-sm hover:underline"
                                    onClick={() => removeAppFromRanking(index)}
                                >
                                    Remove
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>

                <button
                    onClick={saveRanking}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Save Ranking and App of the Day
                </button>
            </div>
        </div>
    );
};

export default Appranking;
