import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { doc, getDoc, updateDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

// Utility to generate unique IDs for new questions or answers
const generateUniqueId = () => {
  return Math.random().toString(36).substr(2, 9);
};

const questionTypes = [
  { value: "text", label: "Text Answer" },
  { value: "multiple", label: "Multiple Choice" },
  { value: "rating", label: "Rating Scale" },
  // Add additional types as needed
];

const EditSurveys = () => {
  const { surveyId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // Survey metadata state
  const [surveyName, setSurveyName] = useState("");
  const [surveySubText, setSurveySubText] = useState("");
  // surveyTime now represents duration in minutes (integer)
  const [surveyTime, setSurveyTime] = useState("");

  // Survey questions state
  const [questions, setQuestions] = useState([]);

  // Country-values associations state and inputs for new entries
  const [countryValues, setCountryValues] = useState([]);
  const [newCountry, setNewCountry] = useState("");
  const [newValue, setNewValue] = useState("");

  // Fetch countries from Firestore
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesRef = collection(db, "countries");
        const countriesSnap = await getDocs(countriesRef);
        const countriesList = countriesSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCountries(countriesList);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    fetchCountries();
  }, []);

  // Fetch survey data from Firestore when component mounts
  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const surveyDocRef = doc(db, "surveys", surveyId);
        const surveyDocSnap = await getDoc(surveyDocRef);
        if (surveyDocSnap.exists()) {
          const data = surveyDocSnap.data();
          if (data.name) setSurveyName(data.name);
          if (data.subText) setSurveySubText(data.subText);
          if (data.time) setSurveyTime(data.time);
          if (data.countryValues) setCountryValues(data.countryValues);
          const loadedQuestions = data.questions.map((question) => ({
            questionId: question.questionId,
            questionType: question.questionType || "text",
            questionTextJSON: JSON.stringify(question.questionText, null, 2),
            answers: question.answers.map((answer) => ({
              answerId: answer.answerId,
              answerTextJSON: JSON.stringify(answer.answerText, null, 2),
            })),
          }));
          setQuestions(loadedQuestions);
        } else {
          alert("Survey not found.");
          navigate("/surveys");
        }
      } catch (error) {
        console.error("Error fetching survey:", error);
        alert("Error fetching survey data.");
      } finally {
        setLoading(false);
      }
    };
    fetchSurvey();
  }, [surveyId, navigate]);

  // Handlers for questions and answers
  const handleQuestionChange = (qIndex, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleAnswerChange = (qIndex, aIndex, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].answers[aIndex][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleQuestionTypeChange = (qIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].questionType = value;
    setQuestions(updatedQuestions);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        questionId: generateUniqueId(),
        questionType: "text",
        questionTextJSON: '{"en": "", "fr": ""}',
        answers: [
          {
            answerId: generateUniqueId(),
            answerTextJSON: '{"en": "", "fr": ""}',
          },
        ],
      },
    ]);
  };

  const addAnswer = (qIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].answers.push({
      answerId: generateUniqueId(),
      answerTextJSON: '{"en": "", "fr": ""}',
    });
    setQuestions(updatedQuestions);
  };

  // Handlers for country-values
  const addCountryValue = () => {
    if (!newCountry) {
      alert("Please select a country.");
      return;
    }
    if (newValue === "") {
      alert("Please enter a value.");
      return;
    }
    if (countryValues.some((cv) => cv.country === newCountry)) {
      alert("This country is already added. You can edit its value.");
      return;
    }
    // Parse the new value as an integer.
    setCountryValues([...countryValues, { country: newCountry, value: parseInt(newValue, 10) }]);
    setNewCountry("");
    setNewValue("");
  };

  const handleCountryValueChange = (index, value) => {
    const updated = [...countryValues];
    updated[index].value = parseInt(value, 10);
    setCountryValues(updated);
  };

  const removeCountryValue = (countryIso) => {
    setCountryValues(countryValues.filter((cv) => cv.country !== countryIso));
  };

  const updateSurvey = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      alert("You need to be logged in to update a survey.");
      return;
    }

    const parsedQuestions = [];
    for (const question of questions) {
      try {
        const questionText = JSON.parse(question.questionTextJSON);
        const parsedAnswers = [];
        for (const answer of question.answers) {
          const answerText = JSON.parse(answer.answerTextJSON);
          parsedAnswers.push({
            answerId: answer.answerId,
            answerText,
          });
        }
        parsedQuestions.push({
          questionId: question.questionId,
          questionType: question.questionType,
          questionText,
          answers: parsedAnswers,
        });
      } catch (error) {
        alert("Invalid JSON in one of the question or answer fields. Please check your inputs.");
        return;
      }
    }

    try {
      const surveyDocRef = doc(db, "surveys", surveyId);
      await updateDoc(surveyDocRef, {
        name: surveyName,
        subText: surveySubText,
        time: surveyTime,
        questions: parsedQuestions,
        countryValues: countryValues,
      });
      alert("Survey updated successfully!");
      navigate("/surveys");
    } catch (error) {
      console.error("Error updating survey:", error);
      alert("Failed to update survey.");
    }
  };

  if (loading) {
    return <div className="p-8">Loading survey...</div>;
  }

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Edit Survey</h1>

      {/* Survey Metadata Section */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-1">Survey Name</label>
        <input
          type="text"
          value={surveyName}
          onChange={(e) => setSurveyName(e.target.value)}
          className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
          placeholder="Enter survey name"
        />

        <label className="block text-sm font-medium text-gray-700 mb-1">Survey Sub Text</label>
        <input
          type="text"
          value={surveySubText}
          onChange={(e) => setSurveySubText(e.target.value)}
          className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
          placeholder="Enter survey sub text"
        />

        <label className="block text-sm font-medium text-gray-700 mb-1">Survey Duration (minutes)</label>
        <input
          type="number"
          value={surveyTime}
          onChange={(e) => setSurveyTime(e.target.value)}
          className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter duration in minutes"
        />
      </div>

      {/* Country Values Section */}
      <div className="mb-4">
        <h2 className="font-semibold mb-2">Country Values</h2>
        <div className="flex items-center space-x-2 mb-2">
          <select
            value={newCountry}
            onChange={(e) => setNewCountry(e.target.value)}
            className="px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select a country</option>
            {countries.map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.name} ({country.isoCode})
              </option>
            ))}
          </select>
          <input
            type="number"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            placeholder="Value"
            className="px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={addCountryValue}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
          >
            Add
          </button>
        </div>
        <ul>
          {countryValues.map((cv, index) => (
            <li key={cv.country} className="flex items-center justify-between">
              <span className="w-1/3">{cv.country}: {cv.value}</span>
              <button
                onClick={() => removeCountryValue(cv.country)}
                className="text-red-500 ml-2"
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Survey Questions */}
      <h2 className="font-semibold mb-2">Survey Questions</h2>
      {questions.map((question, qIndex) => (
        <div key={question.questionId} className="mb-6 border p-4 rounded">
          <div className="flex items-center justify-between mb-2">
            <h3 className="font-bold">Question {qIndex + 1}</h3>
            <select
              value={question.questionType}
              onChange={(e) => handleQuestionTypeChange(qIndex, e.target.value)}
              className="px-3 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {questionTypes.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
          </div>
          <textarea
            placeholder='Question text translations (e.g., {"en": "Question", "fr": "Question"})'
            value={question.questionTextJSON}
            onChange={(e) => handleQuestionChange(qIndex, "questionTextJSON", e.target.value)}
            className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
          />
          <h4 className="font-semibold mb-2">Answers</h4>
          {question.answers.map((answer, aIndex) => (
            <div key={answer.answerId} className="mb-4 grid grid-cols-4 gap-4">
              <textarea
                placeholder='Answer text translations (e.g., {"en": "Answer", "fr": "Réponse"})'
                value={answer.answerTextJSON}
                onChange={(e) => handleAnswerChange(qIndex, aIndex, "answerTextJSON", e.target.value)}
                className="col-span-3 px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={() => addAnswer(qIndex)}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
              >
                + Add Answer
              </button>
            </div>
          ))}
        </div>
      ))}
      <button
        onClick={addQuestion}
        className="mb-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
      >
        + Add Question
      </button>
      <div className="mt-6">
        <button
          onClick={updateSurvey}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Save Survey
        </button>
      </div>
    </div>
  );
};

export default EditSurveys;
