import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";

const AppDetails = () => {
  const { bundleId } = useParams();
  const [appData, setAppData] = useState(null);
  const [sponsoredLink, setSponsoredLink] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchAppDetails = async () => {
      try {
        const appDocRef = doc(db, "apps", bundleId);
        const appDocSnap = await getDoc(appDocRef);
        if (appDocSnap.exists()) {
          const data = appDocSnap.data();
          setAppData(data);
          // Load the sponsoredLink, or default to an empty string
          setSponsoredLink(data.sponsoredLink || "");
        } else {
          setError("App not found.");
        }
      } catch (err) {
        console.error("Error fetching app details:", err);
        setError("An error occurred while fetching app details.");
      }
    };

    fetchAppDetails();
  }, [bundleId]);

  const handleSponsoredLinkChange = (e) => {
    setSponsoredLink(e.target.value);
  };

  const handleSaveSponsoredLink = async () => {
    try {
      const appDocRef = doc(db, "apps", bundleId);
      await updateDoc(appDocRef, { sponsoredLink });
      // Optionally update the local state to reflect the new value
      setAppData((prev) => ({ ...prev, sponsoredLink }));
      setEditMode(false);
    } catch (err) {
      console.error("Error updating sponsored link:", err);
      setError("An error occurred while updating the sponsored link.");
    }
  };

  if (error) return <p>{error}</p>;
  if (!appData) return <p>Loading...</p>;

  return (
    <div className="p-8 pt-24">
      <h1 className="text-2xl font-bold mb-4">{appData.title}</h1>
      <img
        src={appData.icon}
        alt={`${appData.title} icon`}
        className="w-32 h-32 mb-4"
      />
      <p className="mb-2"><strong>Developer:</strong> {appData.developer}</p>
      <p className="mb-2"><strong>Description:</strong> {appData.description}</p>
      {/* You can add more fields here as needed */}
      
      {/* Sponsored Link Section */}
      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-2">Sponsored Link</h2>
        {editMode ? (
          <div>
            <input
              type="text"
              value={sponsoredLink}
              onChange={handleSponsoredLinkChange}
              className="border p-2 rounded w-full"
              placeholder="Enter sponsored link URL"
            />
            <div className="mt-2">
              <button
                onClick={handleSaveSponsoredLink}
                className="px-4 py-2 bg-blue-500 text-white rounded mr-2"
              >
                Save
              </button>
              <button
                onClick={() => setEditMode(false)}
                className="px-4 py-2 bg-gray-300 text-black rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div>
            <p className="mb-2">
              {sponsoredLink ? sponsoredLink : "No sponsored link set."}
            </p>
            <button
              onClick={() => setEditMode(true)}
              className="px-4 py-2 bg-green-500 text-white rounded"
            >
              Edit Sponsored Link
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppDetails;
