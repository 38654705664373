import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { db } from "../firebaseConfig";
import { doc, setDoc, collection, getDocs } from "firebase/firestore";

// Utility to generate unique IDs for new questions or answers
const generateUniqueId = () => {
  return Math.random().toString(36).substr(2, 9);
};

// List of available question types
const questionTypes = [
  { value: "text", label: "Text Answer" },
  { value: "multiple", label: "Multiple Choice" },
  { value: "rating", label: "Rating Scale" },
  // Add more models as needed
];

const CreateSurveys = () => {
  // Survey metadata state
  const [surveyName, setSurveyName] = useState("");
  const [surveySubText, setSurveySubText] = useState("");
  // surveyTime now represents duration in minutes (integer)
  const [surveyTime, setSurveyTime] = useState("");

  // Survey questions state
  const [questions, setQuestions] = useState([
    {
      questionId: generateUniqueId(),
      questionTextJSON: '{"en": "", "fr": ""}',
      answers: [
        {
          answerId: generateUniqueId(),
          answerTextJSON: '{"en": "", "fr": ""}',
        },
      ],
    },
  ]);

  // Countries fetched from Firestore
  const [countries, setCountries] = useState([]);
  // Country-value associations state (each item is { country: ISO, value: number })
  const [countryValues, setCountryValues] = useState([]);
  // For adding a new country-value pair
  const [newCountry, setNewCountry] = useState("");
  const [newValue, setNewValue] = useState("");

  // Fetch countries from Firestore
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesRef = collection(db, "countries");
        const countriesSnap = await getDocs(countriesRef);
        const countriesList = countriesSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCountries(countriesList);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  // Handler for adding a new country-value pair (storing value as int)
  const addCountryValue = () => {
    if (!newCountry) {
      alert("Please select a country.");
      return;
    }
    if (newValue === "") {
      alert("Please enter a value.");
      return;
    }
    // Avoid duplicate entries for the same country
    if (countryValues.some((cv) => cv.country === newCountry)) {
      alert("This country is already added. You can edit its value.");
      return;
    }
    setCountryValues([...countryValues, { country: newCountry, value: parseInt(newValue, 10) }]);
    setNewCountry("");
    setNewValue("");
  };

  // Handler for removing a country-value association
  const removeCountryValue = (countryIso) => {
    setCountryValues(countryValues.filter((cv) => cv.country !== countryIso));
  };

  // Update question text JSON for a specific question
  const handleQuestionChange = (qIndex, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex][field] = value;
    setQuestions(updatedQuestions);
  };

  // Update answer text JSON for a specific answer of a question
  const handleAnswerChange = (qIndex, aIndex, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].answers[aIndex][field] = value;
    setQuestions(updatedQuestions);
  };

  // Add a new question with one default answer
  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        questionId: generateUniqueId(),
        questionTextJSON: '{"en": "", "fr": ""}',
        answers: [
          {
            answerId: generateUniqueId(),
            answerTextJSON: '{"en": "", "fr": ""}',
          },
        ],
      },
    ]);
  };

  // Add a new answer to a specific question
  const addAnswer = (qIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].answers.push({
      answerId: generateUniqueId(),
      answerTextJSON: '{"en": "", "fr": ""}',
    });
    setQuestions(updatedQuestions);
  };

  const saveSurvey = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      alert("You need to be logged in to save a survey.");
      return;
    }

    // Validate and parse JSON for each question and answer
    const parsedQuestions = [];
    for (const question of questions) {
      try {
        const questionText = JSON.parse(question.questionTextJSON);
        const parsedAnswers = [];
        for (const answer of question.answers) {
          const answerText = JSON.parse(answer.answerTextJSON);
          parsedAnswers.push({
            answerId: answer.answerId,
            answerText,
          });
        }
        parsedQuestions.push({
          questionId: question.questionId,
          questionText,
          answers: parsedAnswers,
        });
      } catch (error) {
        alert("Invalid JSON in question or answer fields. Please check your inputs.");
        return;
      }
    }

    try {
      // Create a new document in the "surveys" collection with a generated unique ID.
      const surveyId = generateUniqueId();
      const surveyDocRef = doc(db, "surveys", surveyId);
      const surveyData = {
        userId: user.uid,
        name: surveyName,
        subText: surveySubText,
        time: surveyTime, // surveyTime is a simple integer (minutes)
        questions: parsedQuestions,
        // Save the array of country-value associations (values stored as ints)
        countryValues: countryValues,
      };

      await setDoc(surveyDocRef, surveyData);
      alert("Survey saved successfully!");
    } catch (error) {
      console.error("Error saving survey:", error);
      alert("Failed to save survey.");
    }
  };

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Create a New Survey</h1>

      {/* Survey Metadata */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-1">Survey Name</label>
        <input
          type="text"
          value={surveyName}
          onChange={(e) => setSurveyName(e.target.value)}
          className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
          placeholder="Enter survey name"
        />

        <label className="block text-sm font-medium text-gray-700 mb-1">Survey Sub Text</label>
        <input
          type="text"
          value={surveySubText}
          onChange={(e) => setSurveySubText(e.target.value)}
          className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
          placeholder="Enter survey sub text"
        />

        <label className="block text-sm font-medium text-gray-700 mb-1">Survey Duration (minutes)</label>
        <input
          type="number"
          value={surveyTime}
          onChange={(e) => setSurveyTime(e.target.value)}
          className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter duration in minutes"
        />
      </div>

      {/* Country Values Section */}
      <div className="mb-4">
        <h2 className="font-semibold mb-2">Country Values</h2>
        <div className="flex items-center space-x-2 mb-2">
          <select
            value={newCountry}
            onChange={(e) => setNewCountry(e.target.value)}
            className="px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select a country</option>
            {countries.map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.name} ({country.isoCode})
              </option>
            ))}
          </select>
          <input
            type="number"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            placeholder="Value"
            className="px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={addCountryValue}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
          >
            Add
          </button>
        </div>
        <ul>
          {countryValues.map((cv, index) => (
            <li key={cv.country} className="flex items-center justify-between">
              <span className="w-1/3">{cv.country}: {cv.value}</span>
              <button
                onClick={() => removeCountryValue(cv.country)}
                className="text-red-500 ml-2"
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Survey Questions */}
      <h2 className="font-semibold mb-2">Survey Questions</h2>
      {questions.map((question, qIndex) => (
        <div key={question.questionId} className="mb-6 border p-4 rounded">
          <h3 className="font-bold mb-2">Question {qIndex + 1}</h3>
          <textarea
            placeholder='Question text translations (e.g., {"en": "Question", "fr": "Question"})'
            value={question.questionTextJSON}
            onChange={(e) => handleQuestionChange(qIndex, "questionTextJSON", e.target.value)}
            className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
          />

          {/* Answers for this question */}
          <h4 className="font-semibold mb-2">Answers</h4>
          {question.answers.map((answer, aIndex) => (
            <div key={answer.answerId} className="mb-4 grid grid-cols-4 gap-4">
              <textarea
                placeholder='Answer text translations (e.g., {"en": "Answer", "fr": "Réponse"})'
                value={answer.answerTextJSON}
                onChange={(e) => handleAnswerChange(qIndex, aIndex, "answerTextJSON", e.target.value)}
                className="col-span-3 px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={() => addAnswer(qIndex)}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
              >
                + Add Answer
              </button>
            </div>
          ))}
        </div>
      ))}
      <button
        onClick={addQuestion}
        className="mb-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
      >
        + Add Question
      </button>
      <div className="mt-6">
        <button
          onClick={saveSurvey}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Save Survey
        </button>
      </div>
    </div>
  );
};

export default CreateSurveys;
