import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebaseConfig";

const Surveys = () => {
  const [surveys, setSurveys] = useState([]);
  const [filteredSurveys, setFilteredSurveys] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState({ field: "surveyName", order: "asc" });
  const navigate = useNavigate();

  // Fetch surveys from Firestore and use the "name" field as the survey title.
  useEffect(() => {
    const fetchData = async () => {
      try {
        const surveysRef = collection(db, "surveys");
        const surveysSnap = await getDocs(surveysRef);
        const surveysList = surveysSnap.docs.map((doc) => {
          const data = doc.data();
          const surveyName = data.name || "Untitled Survey";
          return {
            id: doc.id,
            surveyName,
            ...data,
          };
        });
        setSurveys(surveysList);
        setFilteredSurveys(surveysList);
      } catch (error) {
        console.error("Error fetching surveys:", error);
      }
    };

    fetchData();
  }, []);

  // Filter surveys by survey name
  useEffect(() => {
    const filtered = surveys.filter((survey) =>
      (survey.surveyName || "").toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSurveys(filtered);
  }, [searchTerm, surveys]);

  // Sort surveys by survey name
  const handleSort = () => {
    const order = sortBy.order === "asc" ? "desc" : "asc";
    const sorted = [...filteredSurveys].sort((a, b) => {
      const nameA = a.surveyName || "";
      const nameB = b.surveyName || "";
      return order === "asc" ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });

    setSortBy({ field: "surveyName", order });
    setFilteredSurveys(sorted);
  };

  // Delete a survey
  const deleteSurvey = async (surveyId) => {
    if (!window.confirm("Are you sure you want to delete this survey?")) return;
    try {
      await deleteDoc(doc(db, "surveys", surveyId));
      const updatedSurveys = surveys.filter((survey) => survey.id !== surveyId);
      setSurveys(updatedSurveys);
      setFilteredSurveys(
        updatedSurveys.filter((survey) =>
          (survey.surveyName || "").toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      alert("Survey deleted successfully!");
    } catch (error) {
      console.error("Error deleting survey:", error);
      alert("Failed to delete survey.");
    }
  };

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Surveys</h1>

      {/* Create Survey Button and Search */}
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search by survey name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-1/3 px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={() => navigate("/surveys/create")}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
        >
          Create Survey
        </button>
      </div>

      {/* Surveys Table */}
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th
              className="border border-gray-300 px-4 py-2 text-left cursor-pointer"
              onClick={handleSort}
            >
              Survey Name {sortBy.order === "asc" ? "▲" : "▼"}
            </th>
            <th className="border border-gray-300 px-4 py-2 text-center w-20">Edit</th>
            <th className="border border-gray-300 px-4 py-2 text-center w-20">Delete</th>
          </tr>
        </thead>
        <tbody>
          {filteredSurveys.map((survey) => (
            <tr key={survey.id}>
              <td className="border border-gray-300 px-4 py-2">{survey.surveyName}</td>
              <td className="border border-gray-300 px-4 py-2 text-center">
                <button
                  onClick={() => navigate(`/surveys/edit/${survey.id}`)}
                  className="bg-blue-500 text-white px-2 py-1 text-sm rounded hover:bg-blue-600 transition"
                >
                  Edit
                </button>
              </td>
              <td className="border border-gray-300 px-4 py-2 text-center">
                <button
                  onClick={() => deleteSurvey(survey.id)}
                  className="bg-red-500 text-white px-2 py-1 text-sm rounded hover:bg-red-600 transition"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Surveys;
